import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonSlides, AlertController } from '@ionic/angular';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-avatars',
  templateUrl: './avatars.page.html',
  styleUrls: ['./avatars.page.scss'],
})
export class AvatarsPage implements OnInit {
  avatars = [
    {
      key: 'slug',
      name: 'Le «Slug Mould»',
      picture: 'slug.png',
      description: 'Physarum polycephalum, ou encore l’internet de la forêt.   On pourrait lui donner des tas de surnoms à ce petit champignon capable, comme tout champignon qui se respecte de dépolluer des sols. Mais sa super fonction à lui est de se connecter aux arbres et de leur rendre des services de nettoyage et de nutrition par une identification très précise de leurs réseaux d’échanges.   Un calcul des réseaux de distribution avec un super business model très bio-inspirant ! Les études sur son génie sont en cours.'
    },
    {
      key: 'colibri',
      name: 'Le colibri',
      picture: 'colibri.png',
      description: 'C’est le vertébré le plus rapide. Il est 2 fois plus rapide que les avions de combat, et ses accélérations centripètes sont 9 fois plus grandes que l’accélération gravitationnelle. Il a inspiré beaucoup de travaux de recherche dont celui sur l’efficience des pâles d’éoliennes par la technologie cinématique 3D.'
    },
    {
      key: 'apus',
      name: 'L’apus apus',
      picture: 'apus.png',
      description: 'Il passe sa vie dans les airs : 10 mois par an !! Il est capable de dormir en volant et peut parcourir 43 000 kms sans se poser en un mois ! Et parfois jusqu’a 2 500M d’altitude ! Ses performances notamment énergétiques (car il fait tout ça en mangeant trois fois rien),  sont étudiées à travers le monde.'
    },
    {
      key: 'guepard',
      name: 'Le guépard',
      picture: 'guepard.png',
      description: 'Avec ses vitesses de pointe de 112 kms/H et sa grande capacité à changer de direction dans un équilibre parfait, il a inspiré « Cheetah » un robot du M.I.T., très rapide dans ses déplacements et un équilibre à toute épreuve. Ce robot permet de cartographier son environnement dans un milieu trop hostile pour l’humain.'
    },
    {
      key: 'escargot',
      name: 'L’escargot du désert',
      picture: 'escargot.png',
      description: 'Non non vous ne rêvez pas le concept est étrange mais il existe. Ce gastéropode peut résister à des températures proches de 50°C et plusieurs mois d’affilée. Grâce à des écarts de températures à différents niveau de l’architecture de sa coquille, et à une stratégie d’occupation de l’habitat, des courants d’air se forme et apporte à sa maison une climatisation naturelle. Architecture ai-je précisé…grâce à cet animal, à l’Université d’Ispahan, des étudiants ont pu créer un bâtiment à climatisation passive.'
    },
    {
      key: 'diatomee',
      name: 'La diatomée',
      picture: 'diatomee.png',
      description: 'Cette petite algue (il en existerait + de 20 000 espèces), est un organisme unicellulaire qui comme tout plancton qui se respecte, produit beaucoup d’oxygène, mais qui est capable aussi d’une chose incroyable : de fabriquer du verre à température ambiante. Quand on sait que notre verre à nous a besoin de se faire sous des 1500°C, vous pensez que beaucoup de scientifiques ont étudié son secret. Des matériaux de la navette Colombia ont pu être élaborés grâce au génie….de la Diatomée.'
    },
    {
      key: 'rat',
      name: 'Le rat taupe nu',
      picture: 'rat.png',
      description: 'Pas très sexy comme avatar vous me direz, mais derrière ce physique ingrat se cache une véritable merveille de la nature. Tout le monde a plus de chance (le mot est mal choisi) de mourir en vieillissant…Et bien pas lui. On ne peut pas non plus tout avoir la beauté et le secret de la longévité. Il peut vivre jusqu’à 30 ans alors que ses cousins durent 2 à 3 ans maxi. Il a toujours un âge biologique de jeune homme et n’est jamais atteint de cancer. Un animal très bio-inspirant, et beaucoup de scientifiques se penchent sur cet animal génial.'
    },
    {
      key: 'squille',
      name: 'La Squille multicolore ',
      picture: 'squille.png',
      description: 'Elle a permis à elle seule l’élaboration de nombreux projets bio-inspirés en nanotechnologie optique notamment, par la conception d’une caméra de détection des cellules cancéreuses. Ses capacités d’optique hors du commun lui confèrent des talents inouïs. Également, la force de frappe de cette boxeuse créé une telle onde choc qu’elle fait bouillir l’eau. Cette supercavitation a inspiré des chercheurs dans la conception de transports sous marins comme le « Underwater Express Program ».'
    },
    {
      key: 'poulpe',
      name: 'Le poulpe',
      picture: 'poulpe.png',
      description: 'Bon comme ça…ça ne donne pas très envie de le choisir comme avatar, mais ne vous arrêtez pas au nom ou au look. Le poulpe et plus précisément l’Amphioctopus marginatus, a inspiré par sa souplesse et sa dextérité un robot-pieuvre « Octobot » permettant par sa mobilité et ses huit bras, d’être utilisé en chirurgie mini-invasive pour entre autre faire passer des instruments de chirurgie à travers une parois thoracique ou abdominale.'
    },
    {
      key: 'martin',
      name: 'Le Martin-Pêcheur',
      picture: 'martin.png',
      description: 'Il a inspiré le Shinkansen, le TGV japonais, pour l’amélioration de son aérodynamisme, ses performances énergétiques, et le bruit par une meilleure pénétration de l’air. Le martin pêcheur en plus d’être admirablement beau, est capable de maintenir la même vitesse de piqué qu’une fois dans l’eau au moment de la capture de sa proie et malgré le changement de pression. Grâce à lui maintenant le Shinkansen va plus vite avec moins d’électricité et fait beaucoup moins de bruit.'
    },
    {
      key: 'termite',
      name: 'La termite',
      picture: 'termite.png',
      description: 'Il y a 100 millions d’années les termites furent avec les fourmis les premières architectes- designeuses. La termitière est conçue sur des règles de physique et de mathématiques bien avant que l’homme ne s’y soit penché. La termite est capable de concevoir un habitat avec des flux d’air permettant une maîtrise non seulement de la température, mais aussi de la pression atmosphérique et de l’humidité. Cet environnement devient alors parfait pour cultiver leur aliment préféré : le champignon. La termite est à la fois architecte de très haut niveau et a aussi résolu une problématique tout de même importante : l’autosuffisance alimentaire. Et cela quelque soit la zone géographique et donc le climat dans lequel elle se trouve. L’intelligence, la technique, très bon choix d’avatar !'
    },
    {
      key: 'ourson',
      name: 'L’ourson d’eau',
      picture: 'ourson.png',
      description: 'Il mesure seulement quelques dixièmes de millimètres et pourtant, ses pouvoirs sont grands ! De l’équateur au sommet de l’himalaya, ….sur nos plages ou dans le lichen de nos toitures de maison, il est partout, et même au sommet de volcans en activité. Bref, dans le très chaud ou le très froid, lui il est toujours bien. C’est aussi le roi de la cryptobiose : faculté de se mettre dans un état de vie suspendue  (différente de la vie ralentie : l’hypométabolisme, ou de la vie latente, le paucimétabolisme). Il peut « vivre » dans cet état, (bon c’est quand même pas une vie au top),  des centaines d’années ! Il a déjà inspiré des technologies thermostabilisantes (ex : L’entreprise Stablepharma pour la conservation des vaccins), et des systèmes de thermorégulation permettant de faire des économies d’énergie de dingues ! Imaginez le potentiel de technologies que représente ce petit ourson.Vous avez bien fait de le choisir.L’ourson d’eau, c’est l’avenir !'
    },
    {
      key: 'murier',
      name: 'Le murier blanc ',
      picture: 'murier.png',
      description: 'Il symbolise l’intelligence systémique de la nature.   Au départ le murier blanc donne un fruit très riche en anti-oxydants, qui sont les protecteurs des attaques de nos cellules par les radicaux libres, (véritables petites bombes très agressives). Mais s’il n’y avait que cela…Cet arbuste prospère sur des sols arides et attire des bombyx.  Et avant que ces derniers ne soient à l’état de papillons, il participe au développement des chenilles. Celles-ci vont générer une couche de nutriments sur le sol par leurs excréments et les microorganismes qui vont s’y développer. Cette couche de matière organique va transformer le sol aride en sol fertile. Quand à son hôte le bombyx, il est le producteur d’un polymère non issu de la pétrochimie, et pourtant incroyablement résistant : la soie.   Vous avez fait le choix de la polyvalence et des talents multiples. Vive le murier blanc !'
    },
    {
      key: 'araigne',
      name: 'La Néphila Clavipes',
      picture: 'araigne.png',
      description: 'La Néphila Clavipes est une espèce d’araignée et elle tisse de l’or …enfin presque.       C’est même mieux que de l’or ! La soie qu’elle produit est alternée de structures cristallines et non cristallines, et par un pliage des protéines qui composent le fil de sa toile, celle-ci devient extrêmement résistante. Tellement, que ce bio-polymère est un excellent remplaçant de métaux comme l’acier ou le titane, et en plus 6 fois moins cher à produire !! Sans compter bien sûr l’impact environnemental dû à l’extraction de minerais, et les énormes émissions de CO2 qui en découlent…  Alors ? Ces informations vous ont-elles données envie de vous lancer dans l’élevage de Néphila Clavipes ? …Bon sinon un grand scientifique, Monsieur Vollrath et son équipe de l’université d’Oxford, a réussit à faire fabriquer cette soie hyper résistante par des bombyx (que vous retrouverez d’ailleurs dans l’avatar « Murier blanc ». Si vous êtes plus papillons qu’araignées….'
    },
    {
      key: 'edelweiss',
      name: 'L’edelweiss : la plante « anti-UV ». ',
      picture: 'edelweiss.png',
      description: 'Cette fleur est une curiosité. Elle est à la fois belle et intrigante. Mais au delà de ses qualités esthétiques inspirantes, elle est dotée d’une technologie très intéressante pour les peaux qui veulent rester belles et se protéger du soleil. Son petit duvet lui épargne les agressions des ultraviolets nocifs tout en laissant bien passer la lumière pour sa photosynthèse. Si des scientifiques poursuivent les recherches de Monsieur Vigneron sur cette petite fleur, nous pourrions comprendre le fonctionnement de cette absorption magique d’UV, et ne plus être obligés de mettre des crèmes contenant un trop grand nombre de molécules délétères pour notre environnement et notre santé.  L’edelweiss n’a pas encore livré tous ses secrets….Ce ne serait pas le moment de yodeler ?'
    },
    {
      key: 'bombardier ',
      name: 'Le scarabée bombardier',
      picture: 'bombardier.png',
      description: 'Bom-bom bombardier ! Ce coléoptère de 2cm que l’on peut croiser un peu partout sur la planète, est capable de fabriquer une substance chimique qu’il fait chauffer jusqu’à 100°C dans son corps, comme dans une cocotte minute. Puis pour se défendre des prédateurs, notamment les fourmis, il propulse ce liquide chimiquement irritant et bouillant, à une cadence de 20 jets par seconde. Des scientifiques se sont penchés sur ce phénomène incroyable, à la fois sur le plan chimique et physique. Cette découverte inspire aujourd’hui les réflexions concernant les technologies de pulvérisation, et d’autres débouchés sont aussi très nombreux notamment dans l’industrie automobile et la santé. Nous découvrirons les pouvoirs de son cousin le Nosoderma diabolicum qui rejoindra notre liste d’avatars prochainement.'
    }
  ]

  selected: any;
  user: any;

  constructor(
    private modalCtrl: ModalController,
    private firebaseService: FirebaseService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    console.log("user", this.user);

  }
  async save(departement_selected:any) {
    if (this.user.alertes_count && this.user.alertes_count >= 1) {
      this.user.avatar = this.selected;
      this.user["departement_selected"] = departement_selected;
      await this.firebaseService.updateUser(this.user);
    }
    this.close();
  }
  choose(index:any) {
    this.selected = index;
  }

  async presentAlert(i:any) {
    let title = this.avatars[i].name;
    let message = this.avatars[i].description;
    let button = [
      {
        text: 'Fermer',
        role: 'cancel',
        handler: () => {
        }
      }
    ];
    if (this.user && this.user.alertes_count && this.user.alertes_count >= 1) {
      button = [
        {
          text: 'Annuler',
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: 'Choisir',
          role: '',
          handler: () => {
            this.choose(i);
            this.chooseDepartement();
          }
        }
      ];
    }
    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: button
    });
    await alert.present();
  }

  async chooseDepartement() {
    let departements = this.getDepartements();
    let inputs:any = [];
    for (let departement of departements) {
      inputs.push({
        type: 'radio',
        label: departement.departmentCode + " - " + departement.departmentName,
        value: departement.departmentCode

      })
    }
    let buttons = [
      {
        text: 'Annuler',
        role: 'cancel',
        handler: () => {
        }
      },
      {
        text: 'Choisir',
        role: '',
        handler: (departement_selected:any) => {
          console.log('departement_selected', departement_selected);
          this.save(departement_selected);
        }
      }
    ];

    const alert = await this.alertCtrl.create({
      header: "Choix du département",
      message: "Veuillez selectionner un déaprtement",
      inputs:inputs,
      buttons: buttons
    });
    await alert.present();
  }
  close() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  getDepartements() {
    return [
      {
        "departmentCode": "01",
        "departmentName": "Ain",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "02",
        "departmentName": "Aisne",
        "regionCode": "32",
        "regionName": "Nord-Pas-de-Calais-Picardie"
      },
      {
        "departmentCode": "03",
        "departmentName": "Allier",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "04",
        "departmentName": "Alpes-de-Haute-Provence",
        "regionCode": "93",
        "regionName": "Provence-Alpes-Côte d\'Azur"
      },
      {
        "departmentCode": "05",
        "departmentName": "Hautes-Alpes",
        "regionCode": "93",
        "regionName": "Provence-Alpes-Côte d\'Azur"
      },
      {
        "departmentCode": "06",
        "departmentName": "Alpes-Maritimes",
        "regionCode": "93",
        "regionName": "Provence-Alpes-Côte d\'Azur"
      },
      {
        "departmentCode": "07",
        "departmentName": "Ardèche",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "08",
        "departmentName": "Ardennes",
        "regionCode": "44",
        "regionName": "Alsace-Champagne-Ardenne-Lorraine"
      },
      {
        "departmentCode": "09",
        "departmentName": "Ariège",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "10",
        "departmentName": "Aube",
        "regionCode": "44",
        "regionName": "Alsace-Champagne-Ardenne-Lorraine"
      },
      {
        "departmentCode": "11",
        "departmentName": "Aude",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "12",
        "departmentName": "Aveyron",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "13",
        "departmentName": "Bouches-du-Rhône",
        "regionCode": "93",
        "regionName": "Provence-Alpes-Côte d\'Azur"
      },
      {
        "departmentCode": "14",
        "departmentName": "Calvados",
        "regionCode": "28",
        "regionName": "Normandie"
      },
      {
        "departmentCode": "15",
        "departmentName": "Cantal",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "16",
        "departmentName": "Charente",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "17",
        "departmentName": "Charente-Maritime",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "18",
        "departmentName": "Cher",
        "regionCode": "24",
        "regionName": "Centre-Val de Loire"
      },
      {
        "departmentCode": "19",
        "departmentName": "Corrèze",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "21",
        "departmentName": "Côte-d\'or",
        "regionCode": "27",
        "regionName": "Bourgogne-Franche-Comté"
      },
      {
        "departmentCode": "22",
        "departmentName": "Côtes-d\'armor",
        "regionCode": "53",
        "regionName": "Bretagne"
      },
      {
        "departmentCode": "23",
        "departmentName": "Creuse",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "24",
        "departmentName": "Dordogne",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "25",
        "departmentName": "Doubs",
        "regionCode": "27",
        "regionName": "Bourgogne-Franche-Comté"
      },
      {
        "departmentCode": "26",
        "departmentName": "Drôme",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "27",
        "departmentName": "Eure",
        "regionCode": "28",
        "regionName": "Normandie"
      },
      {
        "departmentCode": "28",
        "departmentName": "Eure-et-Loir",
        "regionCode": "24",
        "regionName": "Centre-Val de Loire"
      },
      {
        "departmentCode": "29",
        "departmentName": "Finistère",
        "regionCode": "53",
        "regionName": "Bretagne"
      },
      {
        "departmentCode": "2a",
        "departmentName": "Corse-du-Sud",
        "regionCode": "94",
        "regionName": "Corse"
      },
      {
        "departmentCode": "2b",
        "departmentName": "Haute-Corse",
        "regionCode": "94",
        "regionName": "Corse"
      },
      {
        "departmentCode": "30",
        "departmentName": "Gard",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "31",
        "departmentName": "Haute-Garonne",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "32",
        "departmentName": "Gers",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "33",
        "departmentName": "Gironde",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "34",
        "departmentName": "Hérault",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "35",
        "departmentName": "Ille-et-Vilaine",
        "regionCode": "53",
        "regionName": "Bretagne"
      },
      {
        "departmentCode": "36",
        "departmentName": "Indre",
        "regionCode": "24",
        "regionName": "Centre-Val de Loire"
      },
      {
        "departmentCode": "37",
        "departmentName": "Indre-et-Loire",
        "regionCode": "24",
        "regionName": "Centre-Val de Loire"
      },
      {
        "departmentCode": "38",
        "departmentName": "Isère",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "39",
        "departmentName": "Jura",
        "regionCode": "27",
        "regionName": "Bourgogne-Franche-Comté"
      },
      {
        "departmentCode": "40",
        "departmentName": "Landes",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "41",
        "departmentName": "Loir-et-Cher",
        "regionCode": "24",
        "regionName": "Centre-Val de Loire"
      },
      {
        "departmentCode": "42",
        "departmentName": "Loire",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "43",
        "departmentName": "Haute-Loire",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "44",
        "departmentName": "Loire-Atlantique",
        "regionCode": "52",
        "regionName": "Pays de la Loire"
      },
      {
        "departmentCode": "45",
        "departmentName": "Loiret",
        "regionCode": "24",
        "regionName": "Centre-Val de Loire"
      },
      {
        "departmentCode": "46",
        "departmentName": "Lot",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "47",
        "departmentName": "Lot-et-Garonne",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "48",
        "departmentName": "Lozère",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "49",
        "departmentName": "Maine-et-Loire",
        "regionCode": "52",
        "regionName": "Pays de la Loire"
      },
      {
        "departmentCode": "50",
        "departmentName": "Manche",
        "regionCode": "28",
        "regionName": "Normandie"
      },
      {
        "departmentCode": "51",
        "departmentName": "Marne",
        "regionCode": "44",
        "regionName": "Alsace-Champagne-Ardenne-Lorraine"
      },
      {
        "departmentCode": "52",
        "departmentName": "Haute-Marne",
        "regionCode": "44",
        "regionName": "Alsace-Champagne-Ardenne-Lorraine"
      },
      {
        "departmentCode": "53",
        "departmentName": "Mayenne",
        "regionCode": "52",
        "regionName": "Pays de la Loire"
      },
      {
        "departmentCode": "54",
        "departmentName": "Meurthe-et-Moselle",
        "regionCode": "44",
        "regionName": "Alsace-Champagne-Ardenne-Lorraine"
      },
      {
        "departmentCode": "55",
        "departmentName": "Meuse",
        "regionCode": "44",
        "regionName": "Alsace-Champagne-Ardenne-Lorraine"
      },
      {
        "departmentCode": "56",
        "departmentName": "Morbihan",
        "regionCode": "53",
        "regionName": "Bretagne"
      },
      {
        "departmentCode": "57",
        "departmentName": "Moselle",
        "regionCode": "44",
        "regionName": "Alsace-Champagne-Ardenne-Lorraine"
      },
      {
        "departmentCode": "58",
        "departmentName": "Nièvre",
        "regionCode": "27",
        "regionName": "Bourgogne-Franche-Comté"
      },
      {
        "departmentCode": "59",
        "departmentName": "Nord",
        "regionCode": "32",
        "regionName": "Nord-Pas-de-Calais-Picardie"
      },
      {
        "departmentCode": "60",
        "departmentName": "Oise",
        "regionCode": "32",
        "regionName": "Nord-Pas-de-Calais-Picardie"
      },
      {
        "departmentCode": "61",
        "departmentName": "Orne",
        "regionCode": "28",
        "regionName": "Normandie"
      },
      {
        "departmentCode": "62",
        "departmentName": "Pas-de-Calais",
        "regionCode": "32",
        "regionName": "Nord-Pas-de-Calais-Picardie"
      },
      {
        "departmentCode": "63",
        "departmentName": "Puy-de-Dôme",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "64",
        "departmentName": "Pyrénées-Atlantiques",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "65",
        "departmentName": "Hautes-Pyrénées",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "66",
        "departmentName": "Pyrénées-Orientales",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "67",
        "departmentName": "Bas-Rhin",
        "regionCode": "44",
        "regionName": "Alsace-Champagne-Ardenne-Lorraine"
      },
      {
        "departmentCode": "68",
        "departmentName": "Haut-Rhin",
        "regionCode": "44",
        "regionName": "Alsace-Champagne-Ardenne-Lorraine"
      },
      {
        "departmentCode": "69",
        "departmentName": "Rhône",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "70",
        "departmentName": "Haute-Saône",
        "regionCode": "27",
        "regionName": "Bourgogne-Franche-Comté"
      },
      {
        "departmentCode": "71",
        "departmentName": "Saône-et-Loire",
        "regionCode": "27",
        "regionName": "Bourgogne-Franche-Comté"
      },
      {
        "departmentCode": "72",
        "departmentName": "Sarthe",
        "regionCode": "52",
        "regionName": "Pays de la Loire"
      },
      {
        "departmentCode": "73",
        "departmentName": "Savoie",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "74",
        "departmentName": "Haute-Savoie",
        "regionCode": "84",
        "regionName": "Auvergne-Rhône-Alpes"
      },
      {
        "departmentCode": "75",
        "departmentName": "Paris",
        "regionCode": "11",
        "regionName": "Ile-de-France"
      },
      {
        "departmentCode": "76",
        "departmentName": "Seine-Maritime",
        "regionCode": "28",
        "regionName": "Normandie"
      },
      {
        "departmentCode": "77",
        "departmentName": "Seine-et-Marne",
        "regionCode": "11",
        "regionName": "Ile-de-France"
      },
      {
        "departmentCode": "78",
        "departmentName": "Yvelines",
        "regionCode": "11",
        "regionName": "Ile-de-France"
      },
      {
        "departmentCode": "79",
        "departmentName": "Deux-Sèvres",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "80",
        "departmentName": "Somme",
        "regionCode": "32",
        "regionName": "Nord-Pas-de-Calais-Picardie"
      },
      {
        "departmentCode": "81",
        "departmentName": "Tarn",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "82",
        "departmentName": "Tarn-et-Garonne",
        "regionCode": "76",
        "regionName": "Languedoc-Roussillon-Midi-Pyrénées"
      },
      {
        "departmentCode": "83",
        "departmentName": "Var",
        "regionCode": "93",
        "regionName": "Provence-Alpes-Côte d\'Azur"
      },
      {
        "departmentCode": "84",
        "departmentName": "Vaucluse",
        "regionCode": "93",
        "regionName": "Provence-Alpes-Côte d\'Azur"
      },
      {
        "departmentCode": "85",
        "departmentName": "Vendée",
        "regionCode": "52",
        "regionName": "Pays de la Loire"
      },
      {
        "departmentCode": "86",
        "departmentName": "Vienne",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "87",
        "departmentName": "Haute-Vienne",
        "regionCode": "75",
        "regionName": "Aquitaine-Limousin-Poitou-Charentes"
      },
      {
        "departmentCode": "88",
        "departmentName": "Vosges",
        "regionCode": "44",
        "regionName": "Alsace-Champagne-Ardenne-Lorraine"
      },
      {
        "departmentCode": "89",
        "departmentName": "Yonne",
        "regionCode": "27",
        "regionName": "Bourgogne-Franche-Comté"
      },
      {
        "departmentCode": "90",
        "departmentName": "Territoire de Belfort",
        "regionCode": "27",
        "regionName": "Bourgogne-Franche-Comté"
      },
      {
        "departmentCode": "91",
        "departmentName": "Essonne",
        "regionCode": "11",
        "regionName": "Ile-de-France"
      },
      {
        "departmentCode": "92",
        "departmentName": "Hauts-de-Seine",
        "regionCode": "11",
        "regionName": "Ile-de-France"
      },
      {
        "departmentCode": "93",
        "departmentName": "Seine-Saint-Denis",
        "regionCode": "11",
        "regionName": "Ile-de-France"
      },
      {
        "departmentCode": "94",
        "departmentName": "Val-de-Marne",
        "regionCode": "11",
        "regionName": "Ile-de-France"
      },
      {
        "departmentCode": "95",
        "departmentName": "Val-d\'oise",
        "regionCode": "11",
        "regionName": "Ile-de-France"
      },
      {
        "departmentCode": "971",
        "departmentName": "Guadeloupe",
        "regionCode": "01",
        "regionName": "Guadeloupe"
      },
      {
        "departmentCode": "972",
        "departmentName": "Martinique",
        "regionCode": "02",
        "regionName": "Martinique"
      },
      {
        "departmentCode": "973",
        "departmentName": "Guyane",
        "regionCode": "03",
        "regionName": "Guyane"
      },
      {
        "departmentCode": "974",
        "departmentName": "La Réunion",
        "regionCode": "04",
        "regionName": "La Réunion"
      },
      {
        "departmentCode": "976",
        "departmentName": "Mayotte",
        "regionCode": "06",
        "regionName": "Mayotte"
      },
      {
        "departmentCode": "987",
        "departmentName": "Polynésie Française",
        "regionCode": null,
        "regionName": null
      },
      {
        "departmentCode": "988",
        "departmentName": "Nouvelle Calédonie",
        "regionCode": null,
        "regionName": null
      }
    ];
  }
}
