import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { AlertController, ModalController } from '@ionic/angular';
import { CguPage } from '../cgu/cgu.page';
import { ChartePage } from '../charte/charte.page';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {

  validations_form: FormGroup;
  validations_form_toogle: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';
  step = 0;
  show_password = false;
  show_footer = false;
  id;
  intervalVerify;
  lastVerification = '';

  validation_messages = {
    'email': [
      { type: 'required', message: 'L\'adresse email est obligatoire' },
      { type: 'pattern', message: 'Ton adresse email n\'est pas valide' }
    ],
    'password': [
      { type: 'required', message: 'Le mot de passe est obligatoire' },
      { type: 'minlength', message: 'Le mot de passe doit faire minimum 6 caractères.' }
    ],
    'pseudo': [
      { type: 'required', message: 'Le pseudo est obligatoire' },
      { type: 'minlength', message: 'Le pseudo doit faire minimum 2 caractères.' }
    ],
    'charte': [
      { type: 'required', message: 'L\'acception de la charte est obligatoire' }
    ],
    'cgu': [
      { type: 'required', message: 'L\'acception des cgu et de la politique de confidentialité est obligatoire' }
    ]
  };

  constructor(
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private modalController: ModalController,
    private http: HttpClient,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {
    this.validations_form_toogle = this.formBuilder.group({
      charte: new FormControl(false, Validators.compose([
        Validators.requiredTrue
      ])),
      cgu: new FormControl(false, Validators.compose([
        Validators.requiredTrue
      ])),
    });

    this.validations_form = this.formBuilder.group({
      type: new FormControl('particulier', Validators.compose([
        Validators.required])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.minLength(5),
        Validators.required
      ])),
      pseudo: new FormControl('', Validators.compose([
        Validators.minLength(2),
        Validators.required
      ])),
      code_entreprise: new FormControl('', Validators.compose([]))
    });
    // console.log("validations_form", this.validations_form);
    // this.sendEmailRegister({ email: 'julien.kermarec@me.com', pseudo: 'julienk' })
    console.log("ngonInit", this.id);
    if (this.id != null) {

    }
  }

  ionViewDidLeave() {
    console.log('ionViewDidLeave clearInterval')
    if(this.intervalVerify){
      clearInterval(this.intervalVerify);
      this.intervalVerify = null;
    }
  }

  ionViewDidEnter() {
    console.log("ionViewDidEnter", this.id);
    if (this.id != null) {
      this.refreshVerifier();
    }
    this.show_footer = true;
  }
  async refreshVerifier() {
    let email_verifier = await this.firebaseService.getEmailVerifier(this.id);
    console.log("email_verifier", email_verifier);
    if (email_verifier) {
      this.validations_form.controls["type"].setValue(email_verifier.type);
      this.validations_form.controls["email"].setValue(email_verifier.email);
      this.validations_form.controls["password"].setValue(email_verifier.password);
      this.validations_form.controls["pseudo"].setValue(email_verifier.pseudo);
      this.validations_form.controls["code_entreprise"].setValue(email_verifier.code_entreprise);
      this.tryRegister(this.validations_form.value);
    }
  }

  async toogleCharte() {
    console.log("toogleCharte", this.validations_form_toogle.value.charte);
    if (this.validations_form_toogle.value.charte)
      return;
    else
      this.showCharte();

  }

  //
  async presentAlertInfo() {
    let title = "En cas d'absence de réseau"
    let message = `Vous pouvez prendre une photo avec votre téléphone et signaler
    la pollution plus tard. <br /><br />Dès que vous aurez retrouvé du réseau,
    vous pourrez importer la photo enregistrée, et définir la localisation.`;

    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }
  async showCharte() {
    const modal = await this.modalController.create({
      componentProps: { footer: true },
      component: ChartePage,
      canDismiss: true
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    console.log(data);
    if (data.charte != null) {
      if (!data.charte)
        this.validations_form_toogle.controls["charte"].setValue(false);
      if (data.charte)
        this.validations_form_toogle.controls["charte"].setValue(true);
    }
  }
  async toogleCGV() {
    console.log("toogleCGV", this.validations_form_toogle.value.cgu);
    if (this.validations_form_toogle.value.cgu)
      return;
    else
      this.showCGV();

  }
  async showCGV() {
    const modal = await this.modalController.create({
      component: CguPage,
      canDismiss: true
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    console.log(data);
    if (data.cgu != null) {
      if (!data.cgu)
        this.validations_form_toogle.controls["cgu"].setValue(false);
      if (data.cgu)
        this.validations_form_toogle.controls["cgu"].setValue(true);
    }
  }

  tooglePassword() {
    console.log("tooglePassword", this.show_password)
    this.show_password = !this.show_password;
    console.log("tooglePassword after", this.show_password)
  }

  onEmailChange() {
    setTimeout(() => {
      let email = this.validations_form.controls["email"].value;
      console.log('onEmailChange email', email)
      let email_fixed = this.validations_form.controls["email"].value.toLowerCase().replaceAll(' ', '');
      if (email !== email_fixed) {
        this.validations_form.controls["email"].setValue(email_fixed);
      }
    }, 10)
  }
  onEmailBlur() {
    setTimeout(() => {
      let email = this.validations_form.controls["email"].value;
      console.log('onEmailChange email', email)
      let email_fixed = this.validations_form.controls["email"].value.toLowerCase().replaceAll(' ', '');

      if (email_fixed[email_fixed.length - 1] == '.') {
        email_fixed = email_fixed.slice(0, -1);
      }
      if (email !== email_fixed) {
        this.validations_form.controls["email"].setValue(email_fixed);
      }
    }, 10)
  }
  async presentAlertEmail(value, id = null) {
    console.log("presentAlertEmail", value, id);

    if (id == null) {
      value.email = value.email.toLowerCase().trim();
      let res = await this.firebaseService.createEmailVerifier({ created_at: new Date().toISOString(), email_verified: false, ...value })
      id = res.id;
    }
    await this.sendEmailVerifier(value, id);

    const alert = await this.alertCtrl.create({
      mode: 'ios',
      header: 'Vérification de l\'email',
      message: 'Veuillez valider sur votre boite email votre inscription. Merci ! <br /> Si vous ne trouvez pas l\'email n\'hésitez pas a regarder dans vos spams.',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          cssClass: 'secondary',
          handler: async (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alert.present();
  }
  async tryRegister(value) {
    console.log("tryRegister", value);

    this.validations_form.controls["email"].setValue(value.email.replace(/\s/g, ''));

    if (value.email[value.email.length - 1] == '.') {
      value.email = value.email.slice(0, -1);
    }
    if (!value.email || value.email == '')
      return this.errorMessage = this.validation_messages.email[0].message;
    if (!value.password || value.password == '' || value.password.length < 6)
      return this.errorMessage = this.validation_messages.password[0].message;
    if (!value.pseudo || value.pseudo == '')
      return this.errorMessage = this.validation_messages.pseudo[0].message;


    let user_exist = await this.authService.checkUserExist(value.email)
    console.log("user_exist", user_exist);
    if (user_exist && user_exist.length > 0)
      return this.errorMessage = "L'utilisateur existe déjà (" + value.email + "). Essayer plutôt de vous connecter.";
    else
      this.errorMessage = "";

    // this.errorMessage = '';
    // this.step = 1;
    // return;

    let check = await this.firebaseService.checkEmailVerifier(value.email);
    console.log("check", check);
    if (check && check.length == 0) {
      this.step = 1;
      await this.presentAlertEmail(value);
      this.checkInterval(value.email);
    }
    else {
      if (check[0] && check[0].email_verified) {
        this.errorMessage = '';
        this.step = 2;
        return;
      }
      else {
        await this.presentAlertEmail(value, check[0].id);
        this.step = 1;
        this.checkInterval(value.email);
        // return this.presentAlertEmail(value, check[0].id);
      }
    }
  }

  checkInterval(email) {
    this.intervalVerify = setInterval(async () => {
      let check = await this.firebaseService.checkEmailVerifier(email);
      this.lastVerification = new Date().toISOString();
      if (check && check.length && check[0] && check[0].email_verified) {
        this.step = 2;
        clearInterval(this.intervalVerify);
        this.intervalVerify = null;
      }
    }, 10000)
  }


  register() {
    let value = this.validations_form.value;
    let value_2 = this.validations_form_toogle.value;
    console.log("register", value);
    console.log("register", value_2);
    if (!value_2.charte)
      return this.errorMessage = this.validation_messages.charte[0].message;
    if (!value_2.cgu)
      return this.errorMessage = this.validation_messages.cgu[0].message;
    this.errorMessage = '';

    this.authService.doRegister(value)
      .then(res => {
        // console.log("doRegister", res);
        let user = {
          email: value.email,
          pseudo: value.pseudo,
          status: 1,
          email_verified: false,
          created_at: new Date().toISOString(),
        }
        this.firebaseService.createUser(res.user.uid, user)
      })
      .then(res => {
        return this.sendEmailRegister(value);
      })
      .then(res => {
        // console.log(res);
        this.errorMessage = "";
        this.successMessage = "Votre compte à bien été crée";

        this.close();
      }, err => {
        console.error(err);
        this.errorMessage = err.message;
        this.successMessage = "";
      })
  }


  sendEmailVerifier(data, id) {
    let url_validation = 'https://api.earthency.fr/email-verification.php?id=' + id;
    let url = 'https://api.earthency.fr/mailing';

    let email = data.email;
    let objet = 'Validation inscription Earthency';
    let content = `Hello ${data.pseudo} !
    <br /><br />
    Merci pour votre inscription sur Earthency et votre implication aux côtés des Territoires-Médecins de l'Environnement.
    <br /><br />Pour valider votre inscription <a href="${url_validation}">cliquez ici</a>
    <br /><br />Bien à vous,
    <br />L’équipe Earthency`;

    let form_data = {
      email: email,
      objet: objet,
      body: content
    }
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(url, JSON.stringify(form_data), { headers: httpOptions }).toPromise();
  }

  sendEmailRegister(data) {
    let url = 'https://api.earthency.fr/mailing';

    let email = data.email;
    let objet = 'Bienvenue sur Earthency !';
    let content = `Hello ${data.pseudo} !
    <br /><br />Nous vous remercions d’avoir téléchargé notre application, et vous félicitons pour votre engagement d’observ’acteur de notre environnement et de nos ressources.
    <br /><br />Votre avis est précieux et nous permettra d’améliorer notre outil.Alors n’hésitez pas à nous transmettre vos remarques via cette adresse mail.
    <br /><br />Bien à vous,
    <br />L’équipe Earthency`;

    let form_data = {
      email: email,
      objet: objet,
      body: content
    }
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(url, JSON.stringify(form_data), { headers: httpOptions }).toPromise();
  }

  close(goTo = null) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true,
      'goTo': goTo
    });
  }

  goLoginPage() {
    this.close('login')
  }

}
