import { Injectable } from '@angular/core';

/**
 * MockupService
 *
 * Ce service permet de générer des image de démonstration pour simuler une photo
 */
@Injectable({
  providedIn: 'root'
})
export class MockupService {
  /**
   * Constructeur
   *
   * Crée une instance de MockupService.
   */
  constructor() { }

  /**
   * Random true / false
   */
  randomBoolean() {
    return Math.random() < 0.5;
  }

  /**
   * Génération d'une image de démonstration
   */
  getImageDemo() {
    return new Promise((resolve, reject) => {
      let config:any = {};
      config['randomColor'] = false;
      config['pause'] = false;
      let colors = [
        '#1abc9c',
        '#2ecc71',
        '#3498db',
        '#9b59b6',
        '#f1c40f',
        '#e67e22',
        '#e74c3c'
      ];

      let colorPrimary = colors[Math.floor(Math.random() * colors.length)];
      let colorSecondary = colors[Math.floor(Math.random() * colors.length)];
      while (colorSecondary == colorPrimary) {
        colorSecondary = colors[Math.floor(Math.random() * colors.length)];
      }

      let canvasWidth = 1000;
      let canvasHeight = 1000;
      // if (this.randomBoolean()) {
      //   canvasWidth = 1125;
      //   canvasHeight = 2436;
      // }

      let canvas = document.createElement('canvas');
      canvas.setAttribute('width', canvasWidth.toString());
      canvas.setAttribute('height', canvasHeight.toString());
      let ctx = canvas.getContext('2d');
      let padding = 0;
      let blockStack = 4; // 0, 3 or 6
      this.populateMatrix(
        ctx,
        canvasWidth,
        canvasHeight,
        padding,
        blockStack,
        colorSecondary
      );

      let timestamp = this.getRandomInt(0, 900);
      this.populateMatrix(
        ctx,
        canvasWidth,
        canvasHeight,
        padding,
        blockStack,
        colorSecondary
      );
      this.splitnFill(
        ctx,
        canvasWidth,
        canvasHeight,
        padding,
        blockStack,
        colorPrimary,
        timestamp
      );
      var dataUrl = canvas.toDataURL('image/jpeg');
      var data = dataUrl.replace('data:image/jpeg;base64,', '');
      // let picture_name = new Date().getTime();
      // let picture = {
      //   title: picture_name,
      //   contenu: data,
      //   type: 'image/jpeg'
      // };
      // let picture = { title: picture_name, url: base64Image, blob: blob, type: filetype };
      resolve(data);
    });
  }

  /**
   * Génération d'une carré
   */
  putSquare(ctx:any, x:number, y:number, height:number, width:number, color:string) {
    // console.log("putSquare " + x + "; " + y + " : WIDTH : " + width + " : height : " + height);
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.rect(x, y, width, height);
    ctx.fill();
    ctx.closePath();
  }

  /**
   * Génération de la matrice du canvas
   */
  populateMatrix(ctx:any, width:number, height:number, padding:number, blockStack:number, color:string) {
    let Height = height - 2 * padding;
    let Width = width - 2 * padding;

    let squareHeight = Height / blockStack;
    let squareWidth = Width / blockStack;

    for (let i = 0; i < blockStack; i++) {
      for (let j = 0; j < blockStack; j++) {
        this.putSquare(
          ctx,
          padding + j * squareWidth,
          padding + i * squareHeight,
          squareHeight,
          squareWidth,
          color
        );
      }
    }
  }

  /**
   * Groupement de carrés
   */
  enlight(ctx:any, width:number, height:number, padding:number, blockStack:number, color:string, matrixX:number, matrixY:number) {
    let Height = height - 2 * padding;
    let Width = width - 2 * padding;
    let squareHeight = Height / blockStack;
    let squareWidth = Width / blockStack;

    for (let i = 0; i < blockStack; i++) {
      for (let j = 0; j < blockStack; j++) {
        if (i == matrixY && j == matrixX) {
          this.putSquare(
            ctx,
            padding + j * squareWidth,
            padding + i * squareHeight,
            squareHeight,
            squareWidth,
            color
          );
        }
      }
    }
  }

  /**
   * Coordination du nombre de carrés
   */
  getLocation(number:number) {
    if (number < 3) {
      return [0, number];
    } else if (number < 6) {
      return [1, Math.ceil(number / 3)];
    } else if (number < 9) {
      return [2, Math.ceil(number / 3)];
    } else {
      return [3, Math.ceil(number / 3)];
    }
  }

  /**
   * Génération de carrés en fonction du nombre de carrés voulus
   */
  fillIt(ctx:any, width:number, height:number, padding:number, blockStack:number, color:string, number:number) {
    let coordinate = this.getLocation(number);
    this.enlight(
      ctx,
      width,
      height,
      padding,
      blockStack,
      color,
      coordinate[1],
      coordinate[0]
    );
  }

  /**
   * Génération de carrés dans le contexte
   */
  splitnFill(ctx:any, width:number, height:number, padding:number, blockStack:number, color:string, number:number) {
    let ob = String(number)
      .split('')
      .map(Number);
    let i = 0;
    while (i < ob.length) {
      this.fillIt(ctx, width, height, padding, blockStack, color, ob[i]);
      i++;
    }
  }

  /**
   * Génération d'un nombre aléatoire
   */
  getRandomInt(min:number, max:number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
