import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ModalController } from '@ionic/angular';
import { FirebaseService } from '@app/services/firebase.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  validations_form: FormGroup;
  errorMessage: string = '';
  show_password = false;
  validation_messages = {
    'email': [
      { type: 'required', message: 'L\'adresse email est obligatoire.' },
      { type: 'pattern', message: 'Ton adresse email n\'est pas valide' }
    ],
    'password': [
      { type: 'required', message: 'Le mot de passe est obligatoire.' },
      { type: 'minlength', message: 'Le mot de passe doit faire minimum 6 caractères' }
    ]
  };

  constructor(
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private storage: Storage,
    private router: Router
  ) { }

  ngOnInit() {
    this.validations_form = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.minLength(5),
        Validators.required
      ])),
    });
  }


  close(goTo = null,param=null) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true,
      'goTo': goTo,
      'param': param
    });
  }

  ionViewWillEnter() {
    console.log("login enter");
    this.storage.get('ion_did_tutorial').then(res => {
      if (res !== true) {
        this.router.navigateByUrl('/welcome', { replaceUrl: true });
      }
    });
  }

  async tryLogin(value,from) {
    console.log("tryLogin from", from);
    
    value.email = value.email.toLowerCase().trim();
    let user_exist = await this.authService.checkUserExist(value.email);
    console.log("tryLogin ", user_exist);
    if (!user_exist || user_exist.length == 0) {

      let check = await this.firebaseService.checkEmailVerifier(value.email);
      console.log("check", check);
      if (check && check.length == 0) {
        return this.errorMessage = "L'utilisateur n'existe pas (" + value.email + ")";
      }
      else {
        return this.goRegisterPage(check[0].id);

      }
    }
    this.authService.doLogin(value)
      .then(res => {
        console.log("tryLogin", res);
        this.authService.setCredentials(value);
        this.authService.setLoggedIn(true);
        // this.router.navigateByUrl("/", { skipLocationChange: true });
        this.close();
      }, err => {
        if (err.code == 'auth/wrong-password') {
          this.errorMessage = "Le mot de passe est incorrect"
        }
        else if (err.code == 'auth/user-not-found') {
          this.errorMessage = "L'utilisateur n'existe pas, vérifiez l'adresse email."
        }
        else {
          this.errorMessage = err.message;
        }

        console.error(err)
      })
  }


  tooglePassword() {
    console.log("tooglePassword", this.show_password)
    this.show_password = !this.show_password;
    console.log("tooglePassword after", this.show_password)
  }
  goRegisterPage(param = null) {
    this.close('register',param);
  }

  goLostPasswordPage() {
    this.close('lost-password');
  }
}
