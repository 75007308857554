import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.page.html',
  styleUrls: ['./lost-password.page.scss'],
})
export class LostPasswordPage implements OnInit {

  validations_form: FormGroup;
  errorMessage: string = '';
  showSuccess = false;

  validation_messages = {
    'email': [
      { type: 'required', message: 'L\'adresse email est obligatoire' },
      { type: 'pattern', message: 'Ton adresse email n\'est pas valide' }
    ]
  };

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private router: Router
  ) { }

  ngOnInit() {
    this.validations_form = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ]))
    });
  }

  tryLogin(value) {
    this.authService.doNewPassword(value)
      .then(res => {
        this.showSuccess = true;
        setTimeout(() => {
          this.router.navigateByUrl("/login", { skipLocationChange: true });
        }, 5000)
      }, err => {
        this.errorMessage = err.message;
        console.error(err)
      })
  }


  close(goTo = null) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true,
      'goTo': goTo
    });
  }

  goLoginPage() {
    this.close('login')
  }
}
