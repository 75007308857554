import { NgModule } from '@angular/core';
import { SafeUrlPipe, SafeHtmlPipe, SafeStylePipe } from './safe.pipe';

import { HttpClient } from '@angular/common/http';

//I18n
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/**
 * Chargement des traductions
 */
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
/**
 * Tableaux de déclarations
 */
export const pipes = [
  SafeUrlPipe,
  SafeHtmlPipe,
  SafeStylePipe
];

/**
 * Ensemble des pipes
 *
 * @export
 * @class PipesModule
 */
@NgModule({
  declarations: pipes,
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    SafeUrlPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    TranslateModule,
  ],
  providers: [
  ]
})
export class PipesModule { }
