export const mapStyle = [
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#9cc0f9"
      }
    ]
  }
];