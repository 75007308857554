import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IonInput, ModalController, LoadingController, Platform, ActionSheetController, ToastController, AlertController } from '@ionic/angular';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { MockupService } from '../../services/mockup.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Network } from '@ionic-native/network/ngx';
import { mapStyle } from "./mapStyle";
import * as firebase from 'firebase/app';
import { formatDate } from '@angular/common';
declare const google;

// declare var faceapi: any;
import * as StackBlur from 'stackblur-canvas';
import { AvatarsPage } from '../avatars/avatars.page';

@Component({
  selector: 'app-new',
  templateUrl: './new.page.html',
  styleUrls: ['./new.page.scss'],
})
export class NewPage implements OnInit {
  isModalOpen = false;
  SHARE_MAIL_BODY = "Voilà le lien vers l'application: https://www.earthency.fr/";
  SHARE_MAIL_OBJECT = "Découvre l'application Earthency";
  step = 0;
  saved = false;
  user: any;
  map;
  locality;
  googleAutocomplete: any;
  atteinte_eau = false;
  atteinte_sol = false;
  atteinte_air = false;
  importance = "";
  dechets = false;
  private = false;
  taille = "1";
  description = "";
  localisation_info = "";
  pollution = null;
  user_position: any;
  show_alert = false;
  picture: any;
  fileToUpload: File = null;
  loading: any = null;
  marker_dropped = false;
  localisation: any = {
    text: ''
  };
  commune_abonne = -1;
  shadow = false;
  photo_imported = false;
  edited: boolean = false;
  canShare = false;

  isConnected = false;
  connectSubscription;
  disconnectSubscription;

  @ViewChild("map_canvas", { static: true }) mapElement: ElementRef
  @ViewChild("picture_element", { static: false }) picture_element: ElementRef;
  @ViewChild("file", { static: false }) fileInput: ElementRef;
  @ViewChild("fileCamera", { static: false }) fileCameraInput: ElementRef;
  i18n: string[] = [];

  @ViewChild("localisation_places", { static: true }) localisation_places: IonInput
  constructor(
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private mockupProvider: MockupService,
    private firebaseService: FirebaseService,
    private network: Network,
    private httpClient: HttpClient,
    private platform: Platform,
    private toastCtrl: ToastController
  ) {
    if (window.navigator["share"]) {
      this.canShare = true;
    }


    // watch network for a disconnection
    this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      console.log('network was disconnected :-(');
      this.isConnected = false;
    });

    // watch network for a connection
    this.connectSubscription = this.network.onConnect().subscribe(() => {
      console.log('network connected!');
      this.isConnected = true;
      // We just got a connection but we need to wait briefly
      // before we determine the connection type. Might need to wait.
      // prior to doing any api requests as well.
      // setTimeout(() => {
      //   if (this.network.type === 'wifi') {
      //     console.log('we got a wifi connection, woohoo!');
      //   }
      // }, 3000);
    });
  }

  onScroll(event) {
    // used a couple of "guards" to prevent unnecessary assignments if scrolling in a direction and the var is set already:
    // console.log('event.detail.deltaY', event.detail.scrollTop, event.detail);
    if (event.detail.scrollTop > 5) {
      this.shadow = true;
    }
    else {

      this.shadow = false;
    }
  }

  ngOnInit() {
    this.saved = false;
    this.importance = "";
    this.taille = "1";
    console.log("NewPage", this.user_position);
    this.refreshLang();
    if (this.user_position != null)
      this.getUserAddressBy(this.user_position);
    else {
      this.initMap();
    }
    this.initPlaces();
  }
  selectAtteinte(type) {
    this["atteinte_" + type] = !this["atteinte_" + type];
  }

  selectImportance(importance) {
    this.importance = importance;
  }

  segmentChanged(data) {
    console.log("segmentChanged", data);
  }

  initMap() {
    let style = [];
    style = mapStyle;
    console.log("this.localisation", this.localisation)
    console.log("this.localisation test", (this.localisation.location ? this.localisation.location.lat : 48.128868399999995))
    this.map = new google.maps.Map(this.mapElement.nativeElement, {

      controls: {
        myLocationButton: true,
        indoorPicker: true,
        disableDefaultUI: true,
      },
      styles: style,
      disableDefaultUI: true,
      MyLocationEnabled: true,
      setMyLocationButtonEnabled: true,
      zoomControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      compass: true,
      zoom: 15,
      center: { lat: (this.localisation.location ? this.localisation.location.lat : 47.2580664), lng: (this.localisation.location ? this.localisation.location.lng : 2.4801888) },
      mapTypeId: "roadmap"
    });
    if (this.localisation.location)
      this.map.setZoom(15);
    else
      this.map.setZoom(4);
    var myMarker = new google.maps.Marker({
      position: new google.maps.LatLng((this.localisation.location ? this.localisation.location.lat : 47.2580664), (this.localisation.location ? this.localisation.location.lng : 2.4801888)),
      draggable: true
    });

    google.maps.event.addListener(myMarker, 'dragend', (evt) => {
      console.log('Marker dropped: Current Lat: ' + evt.latLng.lat().toFixed(3) + ' Current Lng: ' + evt.latLng.lng().toFixed(3) + '', evt);
      this.getUserAddressBy({ lat: evt.latLng.lat(), lng: evt.latLng.lng() })
      this.marker_dropped = true;
    });

    google.maps.event.addListener(myMarker, 'dragstart', function (evt) {
      // document.getElementById('current').innerHTML = '<p>Currently dragging marker...</p>';
    });

    this.map.setCenter(myMarker.position);
    myMarker.setMap(this.map);
  }

  refreshLang() {
    // console.log("refresh lang");
    this.translate
      .get([
        "PICTURE_ADDED_TO_GALLERY",
        "PICTURE_DOWNLOADED",
        "ERROR_SAVING_GALLERY",
        "EXPORT_IN_PROGRESS",
        "TITLE_ALERT_LEAVE",
        "MESSAGE_ALERT_LEAVE",
        "SAVE",
        "LEAVE_WITHOUT_SAVE",
        "SELECT_PICTURE",
        "OK",
        "YES",
        "NO",
        "GLOBAL.CANCEL",
        "TITLE",
        "SUBTITLE",
        "DEMO_PICTURE",
        "LOAD_FROM_LIBRARY",
        "LOAD_FROM_COMPUTER",
        "USE_CAMERA"
      ])
      .subscribe(value => {
        this.i18n = value;
        console.log("this.i18n", this.i18n);
      });
  }

  resetLocalisation() {
    this.localisation = {
      text: ''
    };
  }

  async initPlaces() {
    this.googleAutocomplete = new google.maps.places.Autocomplete(await this.localisation_places.getInputElement());

    this.googleAutocomplete.setComponentRestrictions({
      country: 'fr'
    });
    this.googleAutocomplete.addListener('place_changed', (results) => {
      // do whatever here
      console.log("results", results);

      var place = this.googleAutocomplete.getPlace();

      if (!place.geometry) {
        console.log('You entered: ' + place.name);
        return;
      }
      else {

        //   map.setCenter(place.geometry.location);
        console.log("place.geometry.location.lat", place.geometry.location.lat);
        console.log("place.geometry.location.lat()", place.geometry.location.lat());
        // this.setUserPosition(place.geometry.location.lat(), place.geometry.location.lng());

      }

      console.log('You selected: ', place);

      this.localisation = {
        text: place.formatted_address,
        address_components: place.address_components,
        location: {
          lat: place.geometry.location.lat(), lng: place.geometry.location.lng()
        },
        from: 'cursor_map'
      };
    });
  }

  getUserAddressBy(position) {
    // address.results[0].formatted_address
    let url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + position.lat + "," + position.lng + "&key=AIzaSyAjkrsDcV4iXaE94wrLChYzImJYaY-TdW0";
    this.httpClient.get(url)
      .subscribe(data => {
        let address: any = data;
        console.log("result address", address);

        this.localisation = {
          text: address.results[0].formatted_address,
          address_components: address.results[0].address_components,
          location_google: address.results[0].geometry.location,
          location: { lat: position.lat, lng: position.lng },
          from: 'geocode_google'
        }

        this.initMap();
      });
  }


  close(goTo = null) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true,
      'goTo': goTo
    });


    // stop disconnect watch
    this.disconnectSubscription.unsubscribe();

    // stop connect watch
    this.connectSubscription.unsubscribe();
  }

  share() {
    window.navigator["share"]({
      title: "Découvre l'appli Earthency",
      text: "Je viens d'effectuer un signalement sur la commune de " + this.locality + " !",
      url: "https://www.earthency.fr/"
    }).then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }

  // SAVED
  async addPhoto() {
    if (!this.importance)
      return this.presentAlert('Oups..', 'Merci de sélectionner un type de pollution');
    this.step = 1;
  }
  async getLocalityPosition(locality_name: string) {
    let locality_position;
    let url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + locality_name + "&key=AIzaSyAjkrsDcV4iXaE94wrLChYzImJYaY-TdW0";
    let data = await this.httpClient.get(url).toPromise();
    let address: any = data;
    console.log("result address", address);

    locality_position = {
      text: address.results[0].formatted_address,
      address_components: address.results[0].address_components,
      location: address.results[0].geometry.location
    };
    console.log("locality_position", locality_position);
    return locality_position;
  }
  async presentAlertImport() {
    await this.presentAlertPromise('Attention', 'Si vous importez une photo, merci de bien vérifier la géolocalisation sur la cartographie. Vous pouvez déplacer le marqueur afin de guider au mieux les Médécins de l\'Environnement')
    this.presentActionSheet('import');
  }

  async sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async addAlerte() {
    if (this.description.length <= 0 && this.photo_imported) {
      return this.presentAlert('Alerte incomplète', 'Veuillez renseigner le descriptif de la pollution');
    }
    if (!this.localisation.location) {
      return this.presentAlert('Alerte incomplète', 'Veuillez renseigner une localisation');
    }
    if (!this.picture) {
      return this.presentAlert('Alerte incomplète', 'Veuillez ajouter une photo');
    }
    else {
      try {
        console.log('addAlerte A');
        this.loading = await this.loadingCtrl.create({
          message: 'Enregistrement de l\'alerte...'
        });
        this.loading.present();
        console.log('addAlerte B');
        await this.sleep(3000);
        console.log('addAlerte C');
        let picture_url = null;
        if (this.picture)
          picture_url = await this.uploadImageToFirebase(this.picture);

        let currentUser = await this.firebaseService.getCurrentUser();

        let commune_locality = this.localisation.address_components.find((addr_component) => {
          return addr_component.types.indexOf('locality') != -1;
        });
        let commune_postal_code = this.localisation.address_components.find((addr_component) => {
          return addr_component.types.indexOf('postal_code') != -1;
        });
        let locality_position = await this.getLocalityPosition(commune_postal_code + ' ' + commune_locality.short_name);

        let data = {
          user: currentUser.uid,
          localisation: this.localisation,
          user_position: this.user_position,
          locality: commune_locality.short_name,
          locality_position: locality_position,
          importance: this.dechets ? 'urgent' : this.importance,
          taille: this.taille,
          dechets: this.dechets,
          private: this.private,
          show_picture: !this.private,
          atteinte_eau: this.atteinte_eau,
          atteinte_sol: this.atteinte_sol,
          atteinte_air: this.atteinte_air,
          picture: picture_url,
          description: this.description,
          localisation_info: this.localisation_info,
          created_at: new Date().toISOString(),
          statut: 'waiting'
        }

        this.locality = data.locality;

        let communes = await this.firebaseService.getCommunes();
        let commune_abonne = -1;
        if (this.locality != null) {
          commune_abonne = communes.findIndex((commune) => {
            return commune["locality"] == this.locality;
          })
          console.log("commune_abonne", commune_abonne)
        }
        this.commune_abonne = commune_abonne;
        console.log("createAlerte", data);

        this.user = await this.firebaseService.getUser();
        this.firebaseService.createAlerte(data)
          .then(
            async res => {
              console.log('addAlerte D');
              console.log("success", res)
              this.saved = true;
              this.firebaseService.resetAlert();

              let users = await this.firebaseService.getUserByCommuneLocality(commune_locality.short_name);
              console.log("users", users)
              let emails = [];
              for (let user of users) {
                emails.push(user.email);
              }
              console.log("emails", emails);
              console.log("emails join", emails.join(";"));

              await this.sendEmailNewAlerte(data, emails.join(";"), true)
              return this.sendEmailNewAlerte(data, "julien.kermarec@gmail.com;earthency@gmail.com");
              // return this.sendEmailNewAlerte(data,"julien.kermarec@gmail.com;contact@chrnet.fr");
            }, err => {
              console.error('err', err);
            }
          ).then(() => {
            if (!this.user.alertes_count)
              this.user.alertes_count = 1;
            else
              this.user.alertes_count++;
            if (!this.user.points)
              this.user.points = 0;

            this.user.points += 10;
            return this.firebaseService.updateUser(this.user);
          }).then(async () => {
            this.loading.dismiss();



            this.isModalOpen = true;
            await this.sleep(4000);
            this.isModalOpen = false;
          })
      } catch (e) {
        this.presentAlert('Une erreur est survenue', 'Nous n\'avons pas réussi à enregistrer le signalement. <br /><br />(' + e + ')')
        this.loading.dismiss();
      }

    }
  }

  async goAvatars() {

    const modal = await this.modalCtrl.create({
      component: AvatarsPage,
      componentProps: { user: this.user },
      canDismiss: true
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    console.log(data);
  }

  async sendEmailNewAlerte(data, email, commune = false) {

    const formatDays = 'dd/MM/yyyy';
    const formatHour = 'HH:mm';
    const locale = 'en-US';
    const formattedDate = formatDate(new Date().toISOString(), formatDays, locale);
    const formattedHour = formatDate(new Date().toISOString(), formatHour, locale);
    console.log("formattedDate", formattedDate);
    console.log("formattedHour", formattedHour);

    let url = 'https://api.earthency.fr/mailing';

    // let email = 'earthency@gmail.com';
    let objet = 'Nouvelle alerte sur Earthency !';
    let content = `
    <br />Commune : ${data.locality}
    <br />Localisation : ${data.localisation.text}
    <br />Date : ${formattedDate} à ${formattedHour} 
    <br />Taille : ${data.taille}m2
    <br />Importance : ${data.importance}
    <br />Dêchets : ${data.dechets}
    <br />Commentaire : ${data.description}
    <br /><br />Rendez-vous sur le tableau de bord,
    <br /><br />Bien à vous,
    <br /><br />L’équipe Earthency`;

    let form_data = {
      email: email,
      objet: objet,
      body: content
    }
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return await this.httpClient.post(url, JSON.stringify(form_data), { headers: httpOptions }).toPromise();
  }


  uploadImageToFirebase(image: string) {

    return new Promise<any>((resolve, reject) => {
      // console.log("uploadImageToFirebase", image);
      // if(native)
      //   image = this.webview.convertFileSrc(image);
      let randomId = Math.random().toString(36).substr(2, 5);

      //uploads img to firebase storage
      this.firebaseService.uploadBase64(image, randomId)
        .then(data => {
          resolve(data.url);
        }, err => {
          console.error("uploadImage error:", err);
          reject(err);
        })
    });
  }

  //CAMERA
  async presentActionSheet(type) {
    // if (this.platform.is("capacitor"))
    //   this.takePicture(this.camera.PictureSourceType.CAMERA);
    // else {
    if (!this.platform.is("capacitor")) {
      this.triggerFileInputClick();
      return;
    }


    if (type == 'import') {
      this.photo_imported = true;
      this.takePicture(CameraSource.Photos);
    }
    else {
      this.photo_imported = false;
      this.takePicture(CameraSource.Camera);
    }
    // "Vous avez importé une photo"
    // Merci de saisir l'adresse à partie de laquelle la photo a été prise, 
    // et de nous donner des repères géographiques très précis dans 
    // "commentaires"pour que les équipes trouvent facilement la localisation de cette pollution.
    // Merci pour eux!
    /*
    let button_library = {
      text: this.i18n["LOAD_FROM_LIBRARY"],
      handler: () => {
        this.photo_imported = true;
        this.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
      }
    };
    let button_camera = {
      text: this.i18n["USE_CAMERA"],
      handler: () => {
        this.photo_imported = false;
        this.takePicture(this.camera.PictureSourceType.CAMERA);
      }
    };

    // let button_import_web_camera = {
    //   text: 'Prendre une photo',
    //   handler: () => {
    //     this.triggerFileCameraInputClick();
    //   }
    // };
    let button_import_web = {
      text: this.i18n["LOAD_FROM_COMPUTER"],
      handler: () => {
        this.triggerFileInputClick();
        this.photo_imported = true;
      }
    };
    let button_cancel = {
      text: this.i18n["GLOBAL.CANCEL"],
      role: "cancel"
    };
    let button_demo = {
      text: this.i18n["DEMO_PICTURE"],
      handler: () => {
        this.takeDemoPicture();
        this.photo_imported = true;
      }
    };
    let buttons = [button_demo, button_import_web, button_cancel];
    if (this.platform.is("capacitor"))
      buttons = [button_demo, button_library, button_camera, button_cancel];

    let titletext = this.i18n["SELECT_PICTURE"];
    // console.log("titletext",titletext);
    let actionSheet = await this.actionSheetCtrl.create({
      header: titletext,
      buttons: buttons
    });
    actionSheet.present();
    */
  }

  triggerFileInputClick() {

    if (this.fileInput)
      this.fileInput.nativeElement.click();
  }
  triggerFileCameraInputClick() {

    if (this.fileCameraInput)
      this.fileCameraInput.nativeElement.click();
  }

  takeDemoPicture() {
    this.mockupProvider
      .getImageDemo()
      .then((dataUrl: any) => {
        // console.log("takeDemoPicture success");
        return this.getPicture(dataUrl);
      })
      .then(picture => {
        this.edited = true;
      })
      .catch(err => {
        console.error("error loading takeDemoPicture", err);
      });
  }

  async takePicture(sourceType: CameraSource.Camera | CameraSource.Photos) {
    console.log('takePicture', sourceType)

    if (Capacitor.isNativePlatform()) {
      const permissions = await Camera.requestPermissions();
      console.group('permissions', permissions)
      if (permissions.camera == "denied" || permissions.photos == "denied") {
        //A piece of code for displaying an error message to the user
        this.presentAlert('Erreur', 'Veuillez autoriser les permissions photos');
      }
    }
    let result: any;

    result = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      width: 1024,
      height: 1024,
      saveToGallery: true,
      correctOrientation: true,
      resultType: CameraResultType.Base64,
      source: sourceType
    });

    this.getPicture(result.base64String)
      .then(picture => {
        console.log("picture", picture);
        this.edited = true;
      })
      .catch(err => {
        console.error("error loading getPicture", err);
      });
  }


  async onFileInputChange(files: FileList) {
    this.fileToUpload = files.item(0);
    this.photo_imported = true;
    if (this.fileInput && this.fileInput.nativeElement.value != "") {
      // console.log('onFileInputChange', this.fileInput.nativeElement.value);
      // console.log('this.fileToUpload', this.fileToUpload);
      this.fileToBase64(this.fileToUpload);
      console.log("this.fileToUpload", this.fileToUpload);

    }
  }
  fileToBase64(file: any) {
    let reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // console.log(reader.result);
      let data = reader.result.toString();
      data = data.replace("data:image/png;base64,", "");
      data = data.replace("data:image/jpeg;base64,", "");
      data = data.replace("data:image/jpg;base64,", "");
      data = data.replace("data:image/webp;base64,", "");
      data = data.replace("data:image/gif;base64,", "");
      // console.log("file",file);
      // console.log("reader",reader);
      this.edited = true;
      this.getPicture(data);
    };
    reader.onerror = (error: any) => {
      console.log("Error: ", error);
    };
  }

  async getPicture(dataUrl: string) {
    let picture_name = new Date().getTime();
    let picture = {
      title: picture_name,
      contenu: "data:image/png;base64," + dataUrl
    };
    // console.log("getPicture",picture);
    this.picture = picture.contenu;
    setTimeout(() => {
      this.detectFaces();
    }, 2000)
  }

  thumbnailify(base64Image, targetSize, blurs) {
    return new Promise<any>((resolve, reject) => {
      var img = new Image();

      img.onload = function () {
        var width = img.width,
          height = img.height,
          canvas = document.createElement('canvas'),
          ctx: any = canvas.getContext("2d");

        canvas.width = canvas.height = targetSize;

        ctx.drawImage(
          img,
          width > height ? (width - height) / 2 : 0,
          height > width ? (height - width) / 2 : 0,
          width > height ? height : width,
          width > height ? height : width,
          0, 0,
          targetSize, targetSize
        );
        for (let blur of blurs) {
          StackBlur.canvasRGB(canvas, blur.x, blur.y, blur.w, blur.h, 16);
        }

        resolve(canvas.toDataURL());
      };

      img.src = base64Image;
    })
  }

  public async detectFaces() {

    // const MODEL_URL = 'assets/models/'
    // await faceapi.loadTinyFaceDetectorModel(MODEL_URL)
    // await faceapi.loadFaceLandmarkTinyModel(MODEL_URL)
    // await faceapi.loadFaceRecognitionModel(MODEL_URL)
    // const tinyFaceOptions = new faceapi.TinyFaceDetectorOptions({
    //   scoreThreshold: 0.45,
    // });

    // const useTinyModel = true;
    // let picture_element = document.getElementById("picture_element") as HTMLImageElement;
    // let picture_element = await faceapi.fetchImage(this.picture);
    // const detections = await faceapi
    //   .detectAllFaces(picture_element, tinyFaceOptions)
    //   .withFaceLandmarks(useTinyModel)
    //   .withFaceDescriptors();
    // console.log("detections", detections);

    let blurs = [];
    // for (let detection of detections) {

    //   let ratio_width = detection.detection.imageWidth / 300;
    //   let ratio_height = detection.detection.imageHeight / 300;
    //   console.log("ratio_width", ratio_width);
    //   console.log("ratio_height", ratio_height);
    //   blurs.push({
    //     x: Math.floor(detection.alignedRect.box.x / ratio_width),
    //     y: Math.floor(detection.alignedRect.box.y / ratio_height),
    //     w: Math.floor(detection.alignedRect.box.width / ratio_width),
    //     h: Math.floor(detection.alignedRect.box.height / ratio_height),
    //   });
    // }
    let thumbnail = await this.thumbnailify(this.picture, 300, blurs);
    // console.log("thumbnail", thumbnail);
    this.picture = thumbnail;
    // await faceapi.nets.tinyFaceDetector.loadFromUri("assets/models/");
    return;
    // const resizedDetections = faceapi.resizeResults(
    //   detections,
    //   this.picture,
    // );

    // // draw detections into the canvas
    // faceapi.draw.drawDetections(
    //   picture_element.nativeElement,
    //   resizedDetections,
    // );
  }

  deletePicture() {
    this.picture = null;
  }

  async presentLoading(content = "Chargement...") {
    this.loading = await this.loadingCtrl.create({
      message: content
    });

    this.loading.present();
  }

  hideLoading() {
    this.loading.dismiss();
  }

  async presentToast(message: string, duration = 3000) {
    // console.log('presentToast', message)
    let toast = await this.toastCtrl.create({
      message: message,
      duration: duration,
      position: "middle",
      cssClass: "custom_toast"
    });

    toast.present();
  }

  presentAlertMin() {
    console.log("presentAlertMin");
    this.presentAlert("Inférieur à 1m3 ?", "Vous pouvez ramasser ces quelques déchets ? C'est top !  Merci pour votre bonne action. Vous le savez certainement : une B.A. par jour et c'est le bonheur assuré 🙂");
  }
  async presentAlert(title: string, message: string) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }


  async presentAlertPromise(title: string, message: string) {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        header: title,
        message: message,
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            handler: () => {
              resolve(null);
            }
          }
        ]
      });
      await alert.present();

    });
  }


}
