import { Component, ViewChild } from '@angular/core';

import { Platform, MenuController, ModalController, NavController } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { TranslateConfigService } from './services/translate-config.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { Tab1Page } from './pages/tab1/tab1.page';
import { RegisterPage } from './pages/public/register/register.page';
import { CartePage } from './pages/public/carte/carte.page';
import { Storage } from '@ionic/storage-angular';

import { Keyboard } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { AppLauncher } from '@capacitor/app-launcher';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  user: any;
  uuid: any;
  app_status = 'live';
  badge = 0;
  public isAuthenticated = false;
  public selectedIndex = 0;
  public appPages = [
    // {
    //   title: 'Carte pollutions',
    //   url: '/tab1',
    //   icon: 'carte'
    // },
    {
      title: 'Signalement pollution',
      url: '/tab1',
      icon: 'carte'
    },
    {
      title: 'Charte éthique',
      url: '/charte',
      icon: 'charte'
    },
    {
      title: 'Signalements urbains',
      url: '/urbaine',
      icon: 'untitled'
    },
    {
      title: 'Ici Commence la Mer',
      url: '/assainissement',
      icon: 'egout'
    },
    {
      title: 'Climat',
      url: '/cosmetique',
      icon: 'smart'
    },
    {
      title: 'Initiatives vertueuses',
      url: '/evenements',
      icon: 'initiatives'
    },
    {
      title: 'Connaissances',
      url: '/actualites',
      icon: 'pedago'
    },
    {
      title: 'Avis de recherche',
      url: '/avis',
      icon: 'macaw'
    },
    {
      title: 'Quizz',
      url: '/quizz',
      icon: 'question'
    },
    {
      title: 'Mon classement',
      url: '/classement',
      icon: 'classement'
    },
    {
      title: 'Stories',
      url: '/stories',
      icon: 'stories'
    }
  ];
  firstUrl = '';

  constructor(
    public menu: MenuController,
    private platform: Platform,
    private authService: AuthService,
    private router: Router,
    private fcm: FirebaseMessaging,
    private modalCtrl: ModalController,
    private device: Device,
    private route: ActivatedRoute,
    private deeplinks: Deeplinks,
    private navCtrl: NavController,
    private storage: Storage,
    private inAppBrowser: InAppBrowser,
    private translateConfigService: TranslateConfigService,
    public afAuth: AngularFireAuth
  ) {
    router.events.subscribe((val: any) => {
      // see also 
      if (this.firstUrl == '')
        this.firstUrl = val.url && val.url.split("/").length > 2 && val.url.split("/")[1] == 'tab1' ? val.url.split("/")[2] : '';

      // console.log(val);
      if (val.url) {
        switch (val.url) {
          case '/tab1': this.selectedIndex = 0; break;
          case '/charte': this.selectedIndex = 1; break;
          case '/urbaine': this.selectedIndex = 2; break;
          case '/assainissement': this.selectedIndex = 3; break;
          case '/cosmetique': this.selectedIndex = 4; break;
          case '/evenements': this.selectedIndex = 5; break;
          case '/actualites': this.selectedIndex = 6; break;
          case '/avis': this.selectedIndex = 7; break;
          case '/quizz': this.selectedIndex = 8; break;
          case '/classement': this.selectedIndex = 9; break;
          case '/stories': this.selectedIndex = 10; break;
          default: this.selectedIndex = -1; break;
        }
      }
    });
  }

  async ngOnInit() {
    await this.storage.create();
    console.log('this.storage.create');
    this.initializeApp();
    // console.log("window.location.pathname", window.location.pathname);
    const path = window.location.pathname.split('/')[1];
    // console.log("path", path);
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  checkCanOpenUrl = async (type) => {
    let url = '';
    switch (type) {
      case 'facebook': url = (this.platform.is('ios') ? 'fb://' : 'com.facebook.katana'); break;
      case 'linkedin': url = (this.platform.is('ios') ? 'linkedin://' : 'com.linkedin.android'); break;
      case 'instagram': url = (this.platform.is('ios') ? 'instagram://' : 'com.instagram.android'); break;
      default: break;
    }
    const { value } = await AppLauncher.canOpenUrl({ url });

    // com.facebook.orca
    // com.facebook.katana
    // com.example.facebook
    // com.facebook.android
    console.log('Can open url: ', type, url, value);
    return value;
  };

  openDeeplink = async (type, url = '') => {
    switch (type) {
      case 'facebook': url = (this.platform.is('ios') ? 'fb://profile/111422207187646' : 'fb://page/111422207187646'); break;
      case 'linkedin': url = (this.platform.is('ios') ? 'linkedin://company/earthency' : encodeURI('https://www.linkedin.com/company/earthency')); break;
      case 'instagram': url = (this.platform.is('ios') ? 'instagram://user?username=earthency.fr' : 'instagram://user?username=earthency.fr'); break;
      default: break;
    }
    console.log('open url: ', url);
    await AppLauncher.openUrl({ url });
  };

  async openLink(type, link) {
    console.log("type", type);
    console.log("link", link);
    if (link == null || link == '')
      return;
    if (this.platform.is('capacitor')) {
      let canOpen = await this.checkCanOpenUrl(type);
      if (canOpen)
        return await this.openDeeplink(type, link);
    }
    let browser: any;
    const iosoption: InAppBrowserOptions = {
      zoom: 'no',
      location: 'yes',
      toolbar: 'yes',
      clearcache: 'yes',
      clearsessioncache: 'yes',
      disallowoverscroll: 'yes',
      enableViewportScale: 'yes'
    }

    if (this.platform.is('ios')) {
      iosoption.location = 'no';
    }
    browser = this.inAppBrowser.create(link, '_blank', iosoption);
    return browser;
  }

  close() {

    this.menu.close();
  }
  async initializeApp() {

    let lang = await this.translateConfigService.initLang();

    this.translateConfigService.setLanguage(lang);
    this.platform.ready().then(async () => {
      if (this.platform.is('capacitor')) {
        this.subscribeRoutes();
        await StatusBar.setStyle({ style: Style.Light });
        if (this.platform.is('android')) {
          await StatusBar.setBackgroundColor({ color: "#FFFFFF" });
        }
        await SplashScreen.hide();

        Keyboard.addListener('keyboardDidShow', () => {
          document.body.classList.add('keyboard-is-open');
        });
        Keyboard.addListener('keyboardDidHide', () => {
          document.body.classList.remove('keyboard-is-open');
        });
        // Keyboard.setAccessoryBarVisible({isVisible:false});
      }
      else {

        // this.router.navigate(["/welcome/register/"]);
      }

      // this.statusBar.styleDefault();
      // this.statusBar.backgroundColorByHexString("#FFFFFF")
      // this.splashScreen.hide();
      this.platform.backButton.subscribe(() => {
        this.router.navigate(["/tab1"]);
        // code that is executed when the user pressed the back button
      });
      //this.statusBar.overlaysWebView(false);
      this.afAuth.authState.subscribe(user => {
        console.log('afAuth.user.subscribe', user);
        if (user) {
          this.authService.setAnonymous(user.isAnonymous);
          console.log("this.afAuth.authState.subscribe user ok", user);
          this.authService.setLoggedIn(true);
          this.authService.setUser(user);
          this.navCtrl.setDirection('root');
          if (this.firstUrl != '') {
            this.router.navigate(["/tab1/" + this.firstUrl]);
            this.firstUrl = '';
          }
          else
            this.router.navigate(["/tab1"]);
          this.isAuthenticated = true;
          this.menu.enable(true);

          setTimeout(() => {

            this.user = this.authService.getUser();
            console.log("this.user", this.user);
          }, 1000)
          setTimeout(() => {

            this.user = this.authService.getUser();
            console.log("this.user", this.user);
          }, 5000)


          if (this.platform.is('capacitor')) {

            this.modalCtrl.dismiss();
            this.uuid = this.device.uuid
            console.log("uniqueDeviceID get", this.uuid);
            setTimeout(() => {
              this.subscribeNotifications();
            }, 15000);
          }

        } else {
          console.log("this.afAuth.authState.subscribe user null", user);
          this.checkCredentials()
        }
      }, err => {
        this.checkCredentials()
        console.log("this.afAuth.authState.subscribe user err", err);
      }, async () => {
        console.log('this.afAuth.authState.subscribe  finally');
        await SplashScreen.hide();
      })
    });
  }

  subscribeRoutes() {
    console.log('subscribeRoutes');
    this.deeplinks.route({
      '/': Tab1Page,
      '/register': RegisterPage,
      '/carte': CartePage,
      '/carte/:id': CartePage,
      'register': RegisterPage,
      '/register/:id': RegisterPage,
      'register/:id': RegisterPage
    }).subscribe((match) => {
      // match.$route - the route we matched, which is the matched entry from the arguments to route()
      // match.$args - the args passed in the link
      // match.$link - the full link data
      console.log('Successfully matched route', JSON.stringify(match));
    },
      (nomatch) => {
        console.log('subscribeRoutes no match', nomatch);
        // nomatch.$link - the full link data
        // console.error('Got a deeplink that didn\'t match', nomatch, JSON.stringify(nomatch));
        console.log('nomatch["link"]', nomatch["$link"]);
        console.log('nomatch["link"]["host"]', nomatch["$link"]["host"]);
        if (nomatch["$link"]["host"] && nomatch["$link"]["host"] == "register") {
          console.log("is register")
          this.router.navigate(["/welcome" + nomatch["$link"]["path"]]);

        }
        if (nomatch["$link"]["host"] && nomatch["$link"]["host"] == "/register") {
          console.log("is register")
          this.router.navigate(["/welcome/register"]);

        }
        if (nomatch["$link"]["host"] && nomatch["$link"]["host"] == "tab1") {
          console.log("is register")
          this.router.navigate(["/tab1" + nomatch["$link"]["path"]]);
        }
        if (nomatch["$link"]["host"] && nomatch["$link"]["host"] == "/tab1") {
          console.log("is register")
          this.router.navigate(["/tab1" + nomatch["$link"]["path"]]);
        }
        this.subscribeRoutes();

      });

  }

  goPage(url: any) {
    // this.navCtrl.navigateRoot(url);
    this.router.navigateByUrl(url);
  }

  async subscribeNotifications() {
    // if (this.platform.is("ios")) {
    //   let result = await this.fcm.requestPushPermissionIOS();
    //   console.log("subscribeNotifications ios result", result);
    // }
    this.fcm.requestPermission({forceShow: true}).then(async permission => {
      console.log("requestPermission", permission);
      this.fcm.getToken().then(async data => {
        console.log("getToken", data);
        this.registerToken(data);
      });
    });
    this.fcm.onBackgroundMessage().subscribe(data => {
      console.log("Received in background", data, JSON.stringify(data));
      setTimeout(() => {
        console.log("this.app_status ", this.app_status)
        if (this.app_status == 'paused') {
          this.badge++;
          this.fcm.setBadge(this.badge);
        }
        else {
          this.badge--;
          this.fcm.setBadge(this.badge);
        }
      }, 1500)
      // this.presentAlert('Notification', JSON.stringify(data))
    });

    this.fcm.onMessage().subscribe(data => {
      console.log("Received in foreground", data, JSON.stringify(data));
      // this.presentAlert('Notification', JSON.stringify(data))
    });

    // this.fcm.onNotification().subscribe(data => {
    //   if (data.wasTapped) {
    //     console.log("Received in background");
    //   } else {
    //     console.log("Received in foreground");
    //   };
    // });


    this.fcm.onTokenRefresh().subscribe(async data => {
      console.log("onTokenRefresh", data);
      this.registerToken(data);
    });
  }

  async registerToken(data: any) {
    let token = "" + data;
    console.log("registerToken", token);

    let user = await this.authService.getUser();
    console.log("user after get token :", user)
    if (!user.uid)
      user.uid = user.id;
    if (user != null) {
      if (!user["devices"])
        user["devices"] = [{
          uuid: this.uuid,
          token: token
        }];
      else {
        let device = user["devices"].find((device: any) => {
          return device.uuid == this.uuid;
        })
        if (device) {
          if (device.token != token)
            device.token = token;
          else return;
        }
        else {
          user["devices"].push({
            uuid: this.uuid,
            token: token
          });
        }
      }
      console.log("set user", JSON.stringify(user));
      await this.authService.updateUser(user);
    }
  }

  logout() {
    this.authService.doLogout();
  }

  goAccount() {
    this.router.navigate(["/compte"]);
    this.menu.close();
  }
  goCGU() {
    this.router.navigate(["/confidentialite"]);
    this.menu.close();
  }



  async checkCredentials() {
    let credentials = await this.authService.getCredentials();
    console.log("checkCredentials", credentials);
    if (credentials != null) {
      this.authService.doLogin(credentials);
    }
    else {

      let page_actual = JSON.parse(JSON.stringify(this.router.url));
      this.authService.setLoggedIn(false);
      this.authService.setUser(null);
      console.log("page_actual", page_actual, window.location, window.location.href.indexOf('/confidentialite'));
      if (page_actual !== '/carte') {
        console.log("redirect to welcome");
        if (window.location.href.indexOf('/confidentialite') >= 0)
          this.router.navigate(["/confidentialite"]);
        else if (page_actual.indexOf('/welcome') >= 0)
          this.router.navigate([page_actual]);
        else
          this.router.navigate(["/welcome"]);
      }
      this.isAuthenticated = false;
      this.menu.enable(false);

    }
  }
}
