import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonSlides, IonContent } from '@ionic/angular';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-alerte',
  templateUrl: './alerte.page.html',
  styleUrls: ['./alerte.page.scss'],
})
export class AlertePage implements OnInit {
  alerte: any;
  picture_show = 'picture';
  random:any = null;
  random_thank:any = null;
  shadow = false;
  user:any;
  constructor(
    private modalCtrl: ModalController,
    private firebaseService: FirebaseService
  ) { }

  ngOnInit() {
    let arr = [1, 2, 3];
    let rand = Math.random();
    rand *= arr.length;
    this.random = Math.floor(rand);


    arr = [1, 2, 3, 4];
    rand = Math.random();
    rand *= arr.length;
    this.random_thank = Math.floor(rand);

    this.refresh();
  }


  async refresh() {
    this.user = await this.firebaseService.getUser();
    console.log("compte this.user", this.user);
  }

  onScroll(event:any) {
    // used a couple of "guards" to prevent unnecessary assignments if scrolling in a direction and the var is set already:
    // console.log('event.detail.deltaY', event.detail.deltaY);
    if (event.detail.scrollTop > 5) {
      this.shadow = true;
    }
    else {

      this.shadow = false;
    }
  }

  ionSlideDidChange() {
  }

  segmentChanged(data:any) {
    console.log('segmentChanged', data);

  }

  close() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
