import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { take } from "rxjs/operators";
import { Storage } from '@ionic/storage';
import { Events } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  user: any;
  anonymous = false;

  constructor(
    private firebaseService: FirebaseService,
    public afs: AngularFirestore,
    public events: Events,
    public storage: Storage,
    public afAuth: AngularFireAuth
  ) { }

  setAnonymous(anonymous) {
    console.log('setAnonymous', anonymous)
    this.anonymous = anonymous;
  }
  setLoggedIn(_value: any) {
    this.isLoggedIn = _value;
  }

  isAdmin() {
    console.log("isAdmin user", this.user);
    if (this.isLoggedIn && this.user && this.user.status && this.user.status == 3)
      return true;
    return false;
  }

  getUser() {
    return this.user;
  }

  setUser(user: any) {
    if (user != null) {
      console.log("user", user.uid);
      let snapshotChangesRef = this.afs.collection('users').doc(user.uid)
      snapshotChangesRef.valueChanges()
        .pipe(take(1))
        .subscribe((data: any) => {
          console.log("set user data", data);
          if (data != null) {
            data["uid"] = user.uid;
            this.user = {
              ...data,
              isAnonymous: user.isAnonymous
            };
            this.events.publish('user', this.user);
          }
          else {

            this.events.publish('user', null);
          }
        }, err => {
          this.user = null;
          this.events.publish('user', null);
        })
    }
    else {
      this.user = null;
    }
  }

  async updateUser(user: any) {
    await this.afs.collection('users').doc(user.uid).set(user);
    return await this.setUser(user);
  }


  checkUserExist(email: string) {
    console.log('checkUserExist', email);
    return new Promise<any>((resolve, reject) => {
      console.log('checkUserExist promise', email);
      try {
        let snapshotChangesRef = this.afs.collection('users', ref => ref.where('email', '==', email));
        console.log('snapshotChangesRef', snapshotChangesRef);
        snapshotChangesRef.valueChanges()
          .pipe(take(1))
          .subscribe(data => {
            console.log('checkUserExist resolve', data);
            resolve(data);
          }, err => {
            console.log('checkUserExist reject', err);
            reject(err);
          })
      }
      catch (e) {
        console.warn('checkUserExist errror', e);
      }
    });
  }

  isAnonymous(): boolean {
    console.log("isAnonymous", this.user, this.anonymous);
    return this.anonymous;
  }

  isAuthenticated(): boolean {
    return this.isLoggedIn;
  }

  doRegister(value: any) {
    return new Promise<any>(async (resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(value.email, value.password)
        .then(
          res => resolve(res),
          err => reject(err))
    })
  }

  doLogin(value: any) {
    return new Promise<any>(async (resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(value.email, value.password)
        .then(
          res => resolve(res),
          err => reject(err))
    })
  }

  async getCredentials() {
    return await this.storage.get('credentials');
  }
  async setCredentials(value: any) {
    await this.storage.set('credentials', { email: value.email, password: value.password });
  }
  async deleteCredentials() {
    await this.storage.remove('credentials');
  }

  async sendVerificationMail() {
    let currentUser = await this.firebaseService.getCurrentUser();
    if (currentUser)
      currentUser.sendEmailVerification().then(() => {
        console.log('email sent');
      });
  }

  doLoginAnonymous() {
    return new Promise<any>(async (resolve, reject) => {
      this.afAuth.signInAnonymously()
        .then(
          res => resolve(res),
          err => reject(err))
    })
  }

  doNewPassword(value: any) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.sendPasswordResetEmail(value.email)
        .then(
          res => resolve(res),
          err => reject(err))
    })
  }

  doLogout() {
    console.log("doLogout")
    return new Promise(async (resolve, reject) => {
      await this.deleteCredentials();
      this.afAuth.signOut()
        .then((data) => {
          console.log('doLogout success', data);
          this.setLoggedIn(false);
          this.firebaseService.unsubscribeOnLogOut();
          resolve(null);
        }).catch((error) => {
          console.error('doLogout err', error);
          this.setLoggedIn(false);
          console.error(error);
          reject();
        });
    })
  }

  removeUser() {
    return new Promise(async (resolve, reject) => {
      this.afAuth.currentUser.then(async user => {
        await user?.delete()
        await this.doLogout();
      })
    });

  }
}
