import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  language:any;
  constructor(
    private translate: TranslateService,
    private storage: Storage
  ) { }

  getLang() {
    return this.language;
  }
  async initLang() {
    this.language = 'fr';
    return this.language;
    // if (this.language != null)
    //   return this.language;
    // let storage_lang = await this.storage.get('lang');
    // console.log("storage_lang", storage_lang);
    // if (storage_lang != null) {
    //   this.language = storage_lang;
    //   return this.language;
    // }
    // else return this.getDefaultLanguage();
  }
  getDefaultLanguage() {
    let language:any = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    console.log("getDefaultLanguage", language)
    this.language = language;
    return language;
  }

  setLanguage(setLang:string) {
    this.language = setLang;
    this.storage.set('lang', setLang);
    this.translate.use(setLang);
  }

}