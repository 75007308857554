import { Component, ElementRef, ViewChild } from '@angular/core';
import { IonRouterOutlet, ModalController, IonSlides, IonSearchbar, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { mapStyle } from "./mapStyle";
import { FirebaseService } from 'src/app/services/firebase.service';
import { AuthService } from 'src/app/services/auth.service';
declare const google;
declare const MarkerClusterer;

@Component({
  selector: 'app-carte',
  templateUrl: 'carte.page.html',
  styleUrls: ['carte.page.scss']
})
export class CartePage {


  slideOpts = {
    slidesPerView: 1.1,
    spaceBetween: 15,
    centeredSlides: true
  }
  bounds: any;
  infowindow;
  markers = [];
  
  icons = [];

  marker_active:any = null;

  alertes = [];
  communes = [];

  @ViewChild('slider', { static: true }) slider: IonSlides;
  @ViewChild("map", { static: true }) mapElement: ElementRef
  @ViewChild('searchbar', { static: true, read: IonSearchbar }) searchbar: IonSearchbar;
  // @ViewChild('slider') slider: IonSlides;
  map: any;
  googleAutocomplete: any;
  user: any;
  user_position: any = null;
  constructor(
    private firebaseService: FirebaseService,
    private authService: AuthService,
    private router: Router,
    private alertCtrl: AlertController,
    private routerOutlet: IonRouterOutlet,
    private geolocation: Geolocation,
    private alertController: AlertController,
    private modalController: ModalController
  ) {

  }

  ngOnInit() {
    console.log("ngOnInit");
  }

  ionViewDidEnter() {
    console.log("ionViewDidEnter");
    if (google != null)
      this.infowindow = new google.maps.InfoWindow({
        content: "loading..."
      });
    this.loadMap();
    this.initPlaces();
  }
  ionViewDidLoad() {
    console.log("ionViewDidLoad");
    this.removeMarkers();
  }
  ionViewDidLeave() {
    console.log("ionViewDidLeave");
    this.clearMap();
  }

  clearMap() {

    google.maps.event.clearListeners(this.infowindow);
    document.getElementById("map").innerHTML = '';

    var elements = document.getElementsByClassName("tap_listener");


  }
  async initPlaces() {
    this.googleAutocomplete = new google.maps.places.Autocomplete(await this.searchbar.getInputElement());

    this.googleAutocomplete.addListener('place_changed', (results) => {
      // do whatever here
      // console.log("results", results);

      var place = this.googleAutocomplete.getPlace();

      if (!place.geometry) {
        // console.log('You entered: ' + place.name);
        return;
      }
      else {

        //   map.setCenter(place.geometry.location);
        // console.log("place.geometry.location.lat", place.geometry.location.lat);
        // console.log("place.geometry.location.lat()", place.geometry.location.lat());
        this.setUserPosition(place.geometry.location.lat(), place.geometry.location.lng());

      }

      // console.log('You selected: ', place);
    });
  }

  removeMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.markers = [];
    this.icons = [];
  }

  async refresh() {
    this.alertes = null;

    let alertes = await this.firebaseService.getAlertes();
    let communes = await this.firebaseService.getCommunes();
    // console.log("this.alertes before", JSON.parse(JSON.stringify(test)));
    // let final = JSON.parse(JSON.stringify(test));
    // final.unshift({
    //   first: true,
    // });
    // final.push({
    //   last: true,
    // });
    this.communes = communes;
    this.alertes = alertes;
    // this.alertes.push({
    //   last: true,
    // });
    // this.alertes.push({
    //   last: true,
    //   transparent: true
    // });

    // console.log("this.alertes after", JSON.parse(JSON.stringify(this.alertes)));
    this.addAlertes();
    this.getPosition();
  }



  setUserPosition(lat, lng) {
    if (this.user == null) {
      this.user = new google.maps.Marker({
        position: { lat: lat, lng: lng },
        map: this.map
      });
    } else {
      this.user.setPosition({ lat: lat, lng: lng });

      // user.setTitle(place.formatted_address);
    }
    this.map.setCenter({ lat: lat, lng: lng });
  }

  loadMap() {
    let myLocation = { lat: 48.128868399999995, lng: -1.6626363 };
    let style = [];
    style = mapStyle;
    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      controls: {
        myLocationButton: true,
        indoorPicker: true,
        disableDefaultUI: true,
      },
      disableDefaultUI: true,
      MyLocationEnabled: true,
      setMyLocationButtonEnabled: true,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      compass: true,
      zoom: 4,
      center: { lat: 48.862, lng: 2.35 },
      mapTypeId: "roadmap"
    });

    this.bounds = new google.maps.LatLngBounds(); // Zone des markers

    this.refresh();
  }

  getPosition() {
    var options = {
      enableHighAccuracy: true,
      timeout: 1000,
      maximumAge: 0
    };
    console.log("getPosition");
    this.geolocation
      .getCurrentPosition(options)
      .then(resp => {
        // resp.coords.latitude
        // resp.coords.longitude
        // let latLng = new LatLng(resp.coords.latitude, resp.coords.longitude);
        // console.log("setZoom + panTo");
        this.map.setZoom(12);
        this.map.panTo({ lat: resp.coords.latitude, lng: resp.coords.longitude });

        let icon_circle = {
          url: 'assets/markers/location.png',
          scaledSize: new google.maps.Size(40, 40),
          anchor: new google.maps.Point(20, 20),
        };

        this.user_position = { lat: resp.coords.latitude, lng: resp.coords.longitude };
        new google.maps.Marker({
          position: { lat: resp.coords.latitude, lng: resp.coords.longitude },
          map: this.map,
          icon: icon_circle,
          zIndex: 0
        });

      })
      .catch(error => {
        console.log("Error getting location", error.code, error,);
        if (error.code == 1) //User denied Geolocation
          this.presentAlertLocationDenied()
      });

    // let watch = this.geolocation.watchPosition();
    // watch.subscribe(data => {
    //   console.log("watchPosition data", data);

    //   let pos = {
    //     lat: data.coords.latitude,
    //     lng: data.coords.longitude
    //   };

    //   // this.setUserPosition(pos.lat, pos.lng);
    // });
  }



  async presentAlertLocationDenied() {
    console.log("presentAlertLocationDenied");
    const alert = await this.alertController.create({
      mode: 'ios',
      header: 'Localisation introuvable',
      message: 'Aie ! Nous n\'avons pas réussi à vous localiser, il semblerait que vous n\'ayez pas activé le partage de votre localisation, veuillez activer la géolocalisation dans les paramètres de votre téléphone. ',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });
    await alert.present();
  }

  goSwipe() {
    this.router.navigate(["/tab1/swipe"]);
  }

  async goProducts() {
    this.router.navigate(["/tab1/products"]);
  }


  async presentAlert(title:string, message:string) {

    // google.maps.event.clearListeners(this.infowindow);
    // this.refresh();
    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }


  goPrev() {
    // console.log("goPrev marker_active", JSON.parse(JSON.stringify(this.marker_active)));
    if (this.marker_active <= 0)
      this.marker_active = this.markers.length - 1;
    else
      this.marker_active -= 1;
    google.maps.event.trigger(this.markers[this.marker_active], "click");
  }
  goNext() {
    // console.log("goNext marker_active", JSON.parse(JSON.stringify(this.marker_active)));

    if (this.marker_active >= this.markers.length - 1)
      this.marker_active = 0;
    else
      this.marker_active += 1;
    google.maps.event.trigger(this.markers[this.marker_active], "click");
  }
  setInfo(marker) {
    // console.log("marker", marker);
    // console.log("this.alertes[1]", this.alertes[marker.id]);
    let alerte = this.alertes[marker.id];


    let commune_abonne = -1;
    if (alerte["locality"] != null) {
      commune_abonne = this.communes.findIndex((commune) => {
        return commune["locality"] == alerte["locality"];
      })
      console.log("commune_abonne", commune_abonne)
    }



    let options = (alerte.dechets ? 'Déchets' : '') + '' + (alerte.dechets && alerte.degradations ? ', ' : '') + '' + (alerte.degradations ? 'Dégradations' : '');

    let button = '';
    if (alerte["locality"] != null && commune_abonne >= 0) {
      // COMMUNE ABONNÉE ET SIGNALISATION TRAITÉ
      if (alerte.statut == 'ended') {
        button = '<a href="https://play.google.com/store/apps/details?id=com.earthency.earthency"><img style="height: 45px;" src="/assets/google-play.png" /></a>';
        button += '<a href="https://apps.apple.com/fr/app/id1524600085"><img style="height: 45px;" src="/assets/apple.png" /></a>';
      } else {
        // COMMUNE ABONNE ET SIGNALISATION NON TRAITÉ  
        button = '<b>Action en cours</b><br />';
        button += '<a href="https://play.google.com/store/apps/details?id=com.earthency.earthency"><img style="height: 45px;" src="/assets/google-play.png" /></a>';
        button += '<a href="https://apps.apple.com/fr/app/id1524600085"><img style="height: 45px;" src="/assets/apple.png" /></a>';
        //<ion-button mode="ios" class="tap_listener" data-id="' + marker.id + '"  expand="block" color="tertiary">Voir le détail</ion-button>';
      }

    }
    else {
      // color_1 = '#FA530C';
      // color_2 = '#FF9008';
      // COMMUNE NON ABONNÉE
      // if (alerte["locality"] == null || commune_abonne == -1 || alerte.private) {
      button = '<a href="https://play.google.com/store/apps/details?id=com.earthency.earthency"><img style="height: 45px;" src="/assets/google-play.png" /></a>';
      button += '<a href="https://apps.apple.com/fr/app/id1524600085"><img style="height: 45px;" src="/assets/apple.png" /></a>';
    }

    let description = '<i>';
    if (alerte.statut == 'waiting')
      description += 'Signalisation transmise';
    if (alerte.statut == 'planned')
      description += 'Signalisation en cours de traitement';
    if (alerte.statut == 'ended')
      description += 'Signalisation traitée';
    if (alerte.private)
      description += '<br/>Terrain privé (pas de localisation précise)';
    description += '</i><br />';

    // color_2 = '#0c9ef9';
    // color_1 = '#0a86d3';
    if (alerte["locality"] == null || commune_abonne == -1) {
      description += '<span style="color: #0a86d3;font-weight : bold;">Commune non abonnée</span><br />';
    }
    else {
      description += '<span>Commune abonnée</span><br />';
    }

    let message_adresse = "";
    if (alerte.private)
      message_adresse = '<span class="inline">' + alerte.locality + '</span><br />'
    if (!alerte.private)
      message_adresse = '<span class="inline">' + alerte.localisation.text + '</span><br />'

    let content =
      '<div id="content" class="infobox">' +
      '<ion-badge class="badge importance_' + alerte.importance + '">' + alerte.importance + '</ion-badge>' +
      '<ion-badge class="badge taille_' + alerte.taille + '">' + alerte.taille + 'm<sup>3</sup></ion-badge>' +
      '<b style="display : block">' +
      '' + options + '' +
      '</b>' +
      '' +
      '' + message_adresse +
      'Crée le ' + new Date(alerte.created_at).toLocaleDateString() + ' à ' + new Date(alerte.created_at).getHours() + 'h' + new Date(alerte.created_at).getMinutes() + '' +
      '<br />' +
      '' + description + '' +
      '<hr />' +
      '' + button + ''
    '</div>';


    this.infowindow.setContent(content);
  }

  addAlertes() {
    this.removeMarkers();
    this.alertes.map((alerte, i) => {
      if (!alerte.last && !alerte.first) {

        let commune_abonne = -1;
        if (alerte["locality"] != null) {
          commune_abonne = this.communes.findIndex((commune) => {
            return commune["locality"] == alerte["locality"];
          })
          console.log("commune_abonne", commune_abonne)
        }
        alerte.commune_find = true;
        if (alerte["locality"] == null || commune_abonne == -1) {
          alerte.commune_find = false;
        }

        this.generateIcon(alerte, i);
        let selected_location = alerte.localisation.location;
        if (alerte.private && alerte.locality_position) {
          selected_location = alerte.locality_position.location;
          selected_location.lat += 0.003;
        }
        let marker = new google.maps.Marker({
          position: { lat: parseFloat(selected_location.lat), lng: parseFloat(selected_location.lng) },
          optimized: false,
          map: this.map,
          icon: this.icons[i]['classic'],
          zIndex: 1,
        });
        marker.set("id",i);
        google.maps.event.addDomListener(marker, 'click', () => {
          console.log("click", this.marker_active);
          if (this.marker_active != null)
            this.markers[this.marker_active].setIcon(this.icons[this.marker_active]['classic']);
          // console.log("alerte.localisation", alerte.localisation);
          this.marker_active = i;
          this.setInfo(marker);
          this.infowindow.open(this.map, marker);
          this.map.panTo({ lat: parseFloat(alerte.localisation.location.lat) * 1.00006, lng: parseFloat(alerte.localisation.location.lng) });
          this.map.panBy(0, -30);
          marker.setIcon(this.icons[i]['active']);
          // console.log("marker click", marker.id);
          // console.log("marker marker_active", this.marker_active);
          // 
          // console.log("marker",marker);
          // console.log("marker.icon",marker.icon);
          // console.log("marker.icon.src",marker.icon.url);

          // console.log(document.querySelectorAll('img[src="'+marker.icon.url+'"]'));

          // this.slider.slideTo(i);
        });
        this.markers.push(marker);
        return;
      }
    });
    // console.log("this.markers", this.markers);

    let options = {
      cssClass: 'custom-pin'
    };


    // Redéfinit la zone des markers
    // this.setBounds();

    // Ci-dessous, le clustering éventuel

    let cluster; // Le MarkerClusterer
    let clusterOptions = {
      gridSize: 60, // 60 par défaut
      maxZoom: 11, // Désactive le clustering au delà de ce niveau de zoom
      styles: [
        {
          url: '/assets/markers/m1.png',
          height: 53,
          width: 53,
          anchor: [0, 0],
          textColor: '#ffffff',
          textSize: 16
        },
        {
          url: '/assets/markers/m2.png',
          height: 53,
          width: 53,
          anchor: [0, 0],
          textColor: '#ffffff',
          textSize: 16
        },
        {
          url: '/assets/markers/m3.png',
          height: 53,
          width: 53,
          anchor: [0, 0],
          textColor: '#ffffff',
          textSize: 16
        },
        {
          url: '/assets/markers/m4.png',
          height: 53,
          width: 53,
          anchor: [0, 0],
          textColor: '#ffffff',
          textSize: 16
        },
        {
          url: '/assets/markers/m5.png',
          height: 53,
          width: 53,
          anchor: [0, 0],
          textColor: '#ffffff',
          textSize: 16
        },
      ]
      //         height: 45,
      //         width: 45,
      //         anchor: [0, 0],
      //         textColor: '#ffffff',
      //         textSize: 16
      // }]*/
    };

    cluster = new MarkerClusterer(this.map, this.markers, clusterOptions);


  }


  /**
   * Redéfinit le centre de la carte pour l'adapter à la zone des markers,
   * avec un niveau de zoom maximal.
   */
  setBounds() {

    this.map.setCenter(this.bounds.getCenter());
    this.map.fitBounds(this.bounds);
    if (this.map.getZoom() > 13) this.map.setZoom(13);
  }

  async slideChanged() {
    let currentIndex = await this.slider.getActiveIndex();
    this.setActiveCard(currentIndex);

  }

  setActiveCard(index) {
    // console.log("index", index);
    // console.log("alertes", this.alertes[index]);
    // console.log("markers", this.markers);
    // console.log("icons", this.icons);
    // console.log("marker_active", this.marker_active);
    if (this.alertes[index].last || this.alertes[index].first) {
      this.map.setZoom(12);
      // console.log("this.user.getPosition(); ", this.user.getPosition().lat());
      this.map.panTo({ lat: this.user.getPosition().lat(), lng: this.user.getPosition().lng() });
      return
    }

    if (this.marker_active != null) {// && this.marker_active != index - 1) {
      this.markers[this.marker_active - 1].setIcon(this.icons[this.marker_active]['classic']);
    }
    if (this.icons[index] && this.markers[index - 1]) {
      this.markers[index - 1].setIcon(this.icons[index]['active']);
      this.marker_active = this.markers[index - 1].id;
      this.map.setZoom(13);
      this.map.panTo({ lat: parseFloat(this.alertes[index].localisation.location.lat), lng: parseFloat(this.alertes[index].localisation.location.lng) });
      // this.map.panTo({ lat: parseFloat(this.alertes[index].localisation.location.lat), lng: parseFloat(this.alertes[index].localisation.location.lng) });
    }
  }

  generateIcon(alerte:any, i:number) {
    let color_1 = '#FF5752';
    let color_2 = '#DB3A3C';
    let color_active = '#fff';
    let icon_picture = 'm1';


    let commune_abonne = -1;
    if (alerte["locality"] != null) {
      commune_abonne = this.communes.findIndex((commune) => {
        return commune["locality"] == alerte["locality"];
      })
      console.log("commune_abonne", commune_abonne)
    }

    if (alerte.statut == 'ended' && alerte.locality && commune_abonne >= 0) {
      // VERT
      icon_picture = 'm1';
      color_1 = '#0ec254';
      color_2 = '#28e070';
    }
    else {
      icon_picture = 'm2';
      if (!alerte.locality || commune_abonne == -1) {
        // BLEU
        // color_1 = '#bc6c1c';
        // color_2 = '#db924a';
        color_2 = '#0c9ef9';
        color_1 = '#0a86d3';
      }
      else {
        // ORANGE
        color_1 = '#FA530C';
        color_2 = '#FF9008';
      }
    }
    // if (alerte.importance == 'urgent')
    //   icon_picture = 'm3';

    // let icon = {
    //   url: 'assets/markers/' + icon_picture + '.png',
    //   scaledSize: new google.maps.Size(40, 40),
    //   anchor: new google.maps.Point(20, 20),
    // };
    // let icon_active = {
    //   url: 'assets/markers/' + icon_picture + '.png',
    //   scaledSize: new google.maps.Size(60, 60),
    //   anchor: new google.maps.Point(30, 30),
    // };
    let svg = '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <path style="fill:' + color_1 + ';" d="M243.577,506.461c-7.065-7.868-173.004-194.146-173.004-321.035C70.574,83.182,153.756,0,256.001,0 s185.426,83.182,185.426,185.426c0,126.888-165.939,313.167-173.004,321.035C261.786,513.854,250.199,513.839,243.577,506.461z"/> <path style="fill:' + color_2 + ';" d="M441.426,185.426C441.426,83.182,358.244,0,256.001,0v512c4.552,0,9.106-1.843,12.423-5.539 C275.487,498.594,441.426,312.315,441.426,185.426z"/> <path style="fill:#EFEFEF;" d="M256.001,278.719c-51.442,0-93.291-41.851-93.291-93.293s41.851-93.293,93.291-93.293 s93.291,41.851,93.291,93.293S307.441,278.719,256.001,278.719z"/> <path style="fill:#C5D8DF;" d="M256.001,92.134v186.585c51.442,0,93.291-41.851,93.291-93.293S307.441,92.134,256.001,92.134z"/> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>';
    let svg_active = '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <path style="fill:' + color_1 + ';" d="M243.577,506.461c-7.065-7.868-173.004-194.146-173.004-321.035C70.574,83.182,153.756,0,256.001,0 s185.426,83.182,185.426,185.426c0,126.888-165.939,313.167-173.004,321.035C261.786,513.854,250.199,513.839,243.577,506.461z"/> <path style="fill:' + color_2 + ';" d="M441.426,185.426C441.426,83.182,358.244,0,256.001,0v512c4.552,0,9.106-1.843,12.423-5.539 C275.487,498.594,441.426,312.315,441.426,185.426z"/> <path style="fill:' + color_2 + ';" d="M256.001,278.719c-51.442,0-93.291-41.851-93.291-93.293s41.851-93.293,93.291-93.293 s93.291,41.851,93.291,93.293S307.441,278.719,256.001,278.719z"/> <path style="fill:' + color_1 + ';" d="M256.001,92.134v186.585c51.442,0,93.291-41.851,93.291-93.293S307.441,92.134,256.001,92.134z"/> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>';
    let icon_url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg);
    let icon_url_active = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg_active);

    let icon = {
      url: icon_url,
      scaledSize: new google.maps.Size(40, 40),
      anchor: new google.maps.Point(20, 20),
    };
    let icon_active = {
      url: icon_url_active,
      scaledSize: new google.maps.Size(45, 45),
      anchor: new google.maps.Point(25, 25),
    };
    // let icon_white = {
    //   url: 'data:image/svg+xml;utf-8, \
    //   <svg width="40" height="40" viewBox="0 0 40 40"  xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"> \
    //     <image width="40" height="40" xlink:href="' + bar.picture + '"/> \
    //     <circle cx="20" cy="20" r="20" stroke="white" stroke-width="4" fill="transparent" /> \
    //   </svg>',
    //   scaledSize: new google.maps.Size(40, 40),
    //   anchor: new google.maps.Point(20, 20),
    // };

    this.icons[i] = {};
    this.icons[i]["classic"] = icon;
    this.icons[i]["active"] = icon_active;
  }
}
