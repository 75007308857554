import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonContent } from '@ionic/angular';

@Component({
  selector: 'app-charte',
  templateUrl: './charte.page.html',
  styleUrls: ['./charte.page.scss'],
})
export class ChartePage implements OnInit {
  alerte: any;

  footer: any = false;
  disabled = true;

  @ViewChild(IonContent, { static: false }) content: IonContent;
  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {

  }

  ionSlideDidChange($event) {
  }

  segmentChanged(data) {
    console.log('segmentChanged', data);

  }
  logScrollEnd() {

    this.disabled = false;
  }

  // onScroll(ev) {
  //   console.log("ev", ev);
  //   console.log("content", this.content);
  //   console.log("content scrollHeight", this.content["el"].scrollHeight);

  //   if (this.content["el"].scrollTop == this.content["el"].scrollHeight - this.content.cont) {

  //     this.disabled = false;
  //   }
  //   // if (ev.detail.scrollTop > this.content["el"].scrollHeight) {
  //   //   this.disabled = false;
  //   // }
  // }

  close(charte = false) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true,
      'charte': charte
    });
  }

  accept() {
    this.close(true);
  }

}
