import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./pages/public/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'carte',
    children: [
      {
        path: "",
        loadChildren: () => import('./pages/public/carte/carte.module').then(m => m.CartePageModule)
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/public/carte/carte.module').then(m => m.CartePageModule)
      }
    ]
  },
  {
    path: 'tab1',
    children: [
      {
        path: "",
        loadChildren: () => import('./pages/tab1/tab1.module').then(m => m.Tab1PageModule)
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/tab1/tab1.module').then(m => m.Tab1PageModule)
      }
    ]
  },
  {
    path: 'classement',
    loadChildren: () => import('./pages/classement/classement.module').then(m => m.ClassementPageModule)
  },
  {
    path: 'actualites',
    loadChildren: () => import('./pages/actualites/actualites.module').then(m => m.ActualitesPageModule)
  },
  {
    path: 'stories',
    loadChildren: () => import('./pages/stories/stories.module').then(m => m.StoriesPageModule)
  },
  {
    path: 'avis',
    children: [
      {
        path: "",
        loadChildren: () => import('./pages/avis/avis.module').then(m => m.AvisPageModule)
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/avis-details/avis-details.module').then(m => m.AvisDetailsPageModule)
      }
    ]
  },
  {
    path: 'quizz',
    children: [
      {
        path: "",
        loadChildren: () => import('./pages/quizz/quizz.module').then(m => m.QuizzPageModule)
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/quizz-details/quizz-details.module').then(m => m.QuizzDetailsPageModule)
      }
    ]
  },
  {
    path: 'assainissement',
    loadChildren: () => import('./pages/assainissement/assainissement.module').then(m => m.AssainissementPageModule)
  },
  {
    path: 'urbaine',
    loadChildren: () => import('./pages/urbaine/urbaine.module').then(m => m.UrbainePageModule)
  },
  {
    path: 'evenements',
    loadChildren: () => import('./pages/evenements/evenements.module').then(m => m.EvenementsPageModule)
  },
  {
    path: 'confidentialite',
    loadChildren: () => import('./pages/confidentialite/confidentialite.module').then(m => m.ConfidentialitePageModule)
  },
  {
    path: 'charte',
    loadChildren: () => import('./pages/charte-ethique/charte-ethique.module').then(m => m.CharteEthiquePageModule)
  },
  {
    path: 'compte',
    loadChildren: () => import('./pages/compte/compte.module').then(m => m.ComptePageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/public/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'cosmetique', 
    loadChildren: () => import('./pages/cosmetique/cosmetique.module').then(m => m.CosmetiquePageModule)
  },

  {
    path: 'welcome',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/public/welcome/welcome.module').then(m => m.WelcomePageModule)
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/public/welcome/welcome.module').then(m => m.WelcomePageModule)
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
