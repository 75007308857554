import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ModalController, IonRouterOutlet, IonContent } from '@ionic/angular';
import { LoginPage } from '../login/login.page';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.page.html',
  styleUrls: ['./cgu.page.scss'],
})
export class CguPage implements OnInit {
  disabled = true;
  @ViewChild(IonContent, { static: false }) content: IonContent;

  constructor(
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private modalController: ModalController,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onScroll(ev) {
    console.log("ev", ev);
    console.log("content", this.content);

    if (ev.detail.scrollTop > this.content["el"].scrollHeight) {
      this.disabled = false;
    }
  }

  close(cgu = false) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true,
      'cgu': cgu
    });
  }

  accept() {
    this.close(true);
  }

}
