import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import { take } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private snapshotChangesSubscription: any;
  alertes: any = null;
  avis: any = null;
  initiatives: any = null;
  communes: any = null;
  agglos = null;


  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth
  ) { }



  async getAvis() {
    if (this.avis == null)
      await this.getAvisFirebase();

    return this.avis;
  }

  resetAvis() {
    this.avis = null;
  }

  getAvisFirebase() {
    return new Promise<any>((resolve, reject) => {
      let snapshotChangesRef = this.afs.collection('avis');//.orderBy('crea', 'asc'));// ref => ref.where('type', '==', type).orderBy('rank', 'asc').limit(400));
      snapshotChangesRef.snapshotChanges()
        .pipe(take(1))
        .subscribe(snapshots => {
          let result = snapshots.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
          this.avis = result;
          resolve(result);
        }, err => {
          reject(err)
        })
    })
  }


  getAvisPostsFirebase(avi_id: any) {
    return new Promise<any>((resolve, reject) => {
      let snapshotChangesRef = this.afs.collection('avis').doc(avi_id).collection('posts');//.orderBy('crea', 'asc'));// ref => ref.where('type', '==', type).orderBy('rank', 'asc').limit(400));
      snapshotChangesRef.snapshotChanges()
        .pipe(take(1))
        .subscribe(snapshots => {
          let result = snapshots.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
          this.avis = result;
          resolve(result);
        }, err => {
          reject(err)
        })
    })
  }


  async getAlertes() {
    if (this.alertes == null)
      await this.getAlertesFirebase();

    return this.alertes;
  }

  resetAlert() {
    this.alertes = null;
  }

  getAlertesFirebase() {
    console.log('getAlertesFirebase');
    return new Promise<any>((resolve, reject) => {
      let snapshotChangesRef = this.afs.collection('alertes');//.orderBy('crea', 'asc'));// ref => ref.where('type', '==', type).orderBy('rank', 'asc').limit(400));
      snapshotChangesRef.snapshotChanges()
        .pipe(take(1))
        .subscribe(snapshots => {
          console.log('getAlertesFirebase snapshots', snapshots);
          let result = snapshots.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
          this.alertes = result;
          resolve(result);
        }, err => {
          reject(err)
        })
    })
  }

  createClimat(climat: any) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('climats').add(climat)
        .then(
          res => {
            console.log("createClimat res", res);
            resolve(res)
          },
          err => {
            console.log("createClimat err", err);
            reject(err)
          }
        )
    })
  }

  createAlerte(alerte: any) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('alertes').add(alerte)
        .then(
          res => {
            console.log("createAlerte res", res);
            resolve(res)
          },
          err => {
            console.log("createAlerte err", err);
            reject(err)
          }
        )
    })
  }

  updateAlerte(alerte: any) {
    return new Promise<any>((resolve, reject) => {
      console.log("updateAlerte", alerte);
      this.afs.collection('alertes').doc(alerte.id)
        .set(alerte)
        .then(
          res => resolve(res),
          err => reject(err)
        )
    });
  }

  createAssainissement(alerte: any) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('assainissements').add(alerte)
        .then(
          res => {
            console.log("createAssainissement res", res);
            resolve(res)
          },
          err => {
            console.log("createAssainissement err", err);
            reject(err)
          }
        )
    })
  }

  createObservation(alerte: any) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('observations').add(alerte)
        .then(
          res => {
            console.log("createObservations res", res);
            resolve(res)
          },
          err => {
            console.log("createObservations err", err);
            reject(err)
          }
        )
    })
  }

  createAvis(alerte: any) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('avis').add(alerte)
        .then(
          res => {
            console.log("createAvis res", res);
            resolve(res)
          },
          err => {
            console.log("createAvis err", err);
            reject(err)
          }
        )
    })
  }

  createAvisPost(avi: any, post: any) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('avis').doc(avi.id).collection('posts').add(post)
        .then(
          res => {
            console.log("createAvisPost res", res);
            resolve(res)
          },
          err => {
            console.log("createAvisPost err", err);
            reject(err)
          }
        )
    })
  }

  checkEmailVerifier(email: any) {
    console.log("checkEmailVerifier", email);

    return new Promise<any>((resolve, reject) => {
      let snapshotChangesRef = this.afs.collection('emails-verification', ref => ref.where('email', '==', email));
      snapshotChangesRef.snapshotChanges()
        .pipe(take(1))
        .subscribe(snapshots => {
          let result = snapshots.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
          resolve(result);
        }, err => {
          reject(err)
        })
    })
  }
  getEmailVerifier(id: any) {
    console.log("getEmailVerifier", id);

    return new Promise<any>((resolve, reject) => {
      let snapshotChangesRef = this.afs.collection('emails-verification').doc(id);
      snapshotChangesRef.valueChanges()
        .pipe(take(1))
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err)
        })
    })
  }
  createEmailVerifier(verification: any) {
    console.log("createEmailVerifier", verification);
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('emails-verification').add(verification)
        .then(
          res => {
            console.log("createEmailVerifier res", res);
            resolve(res)
          },
          err => {
            console.log("createEmailVerifier err", err);
            reject(err)
          }
        )
    })
  }



  async getCommunes() {
    if (this.communes == null)
      await this.getCommunesFirebase();

    return this.communes;
  }

  resetCommunes() {
    this.communes = null;
  }

  getCommunesFirebase() {
    return new Promise<any>((resolve, reject) => {
      let snapshotChangesRef = this.afs.collection('communes');//.orderBy('crea', 'asc'));// ref => ref.where('type', '==', type).orderBy('rank', 'asc').limit(400));
      snapshotChangesRef.snapshotChanges()
        .pipe(take(1))
        .subscribe(snapshots => {
          let result = snapshots.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
          this.communes = result;
          resolve(result);
        }, err => {
          reject(err)
        })
    })
  }

  async getInitiatives() {
    if (this.initiatives == null)
      await this.getInitiativesFirebase();

    return this.initiatives;
  }

  resetInitiatives() {
    this.initiatives = null;
  }

  getInitiativesFirebase() {
    return new Promise<any>((resolve, reject) => {
      let snapshotChangesRef = this.afs.collection('initiatives');//.orderBy('crea', 'asc'));// ref => ref.where('type', '==', type).orderBy('rank', 'asc').limit(400));
      snapshotChangesRef.snapshotChanges()
        .pipe(take(1))
        .subscribe(snapshots => {
          let result = snapshots.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
          this.initiatives = result;
          resolve(result);
        }, err => {
          reject(err)
        })
    })
  }

  createInitiative(initiative: any) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('initiatives').add(initiative)
        .then(
          res => {
            console.log("createInitiative res", res);
            resolve(res)
          },
          err => {
            console.log("createInitiative err", err);
            reject(err)
          }
        )
    })
  }


  async getAgglos() {
    if (this.agglos == null)
      await this.getAgglosFirebase();

    return this.agglos;
  }

  resetAgglos() {
    this.agglos = null;
  }




  async searchAgglos(search_key, search) {
    console.log("searchAgglos");
    if (this.agglos == null)
      await this.getAgglosFirebase();
    console.log("this.agglos ", this.agglos);
    console.log("search_key", search_key);
    console.log("search", search);
    let result = this.agglos.find((agglo) => {

      console.log('agglo[search_key]', agglo[search_key])
      console.log('search', search)
      console.log('agglo[search_key] === search', agglo[search_key] === search)
      return agglo[search_key] === search;
    });
    console.log("result", result);
    return result;
  }

  getAgglosFirebase() {
    return new Promise<any>((resolve, reject) => {
      let snapshotChangesRef = this.afs.collection('agglos');//.orderBy('crea', 'asc'));// ref => ref.where('type', '==', type).orderBy('rank', 'asc').limit(400));
      snapshotChangesRef.snapshotChanges()
        .pipe(take(1))
        .subscribe(snapshots => {
          let result = snapshots.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
          this.agglos = result;
          resolve(result);
        }, err => {
          reject(err)
        })
    })
  }


  uploadBase64(base64: any, randomId: any) {
    return new Promise<any>(async (resolve, reject) => {
      let currentUser: any = await this.getCurrentUser();

      const storage = getStorage();
      const storageRef = ref(storage, 'alertes/' + currentUser.uid + '/' + randomId);
      uploadString(storageRef, base64, 'data_url')
        .then(snapshot => {
          console.log("uploadBase64 putString success", snapshot);
          getDownloadURL(snapshot.ref)
            .then(res => resolve({
              snapshot: snapshot,
              url: res
            }))
        }, err => {
          console.error(err);
          reject(err);
        })
    })
  }

  uploadBase64Climat(base64: any, randomId: any) {
    return new Promise<any>(async (resolve, reject) => {
      let currentUser: any = await this.getCurrentUser();

      const storage = getStorage();
      const storageRef = ref(storage, 'climat/' + currentUser.uid + '/' + randomId);
      uploadString(storageRef, base64, 'data_url')
        .then(snapshot => {
          console.log("uploadBase64 putString success", snapshot);
          getDownloadURL(snapshot.ref)
            .then(res => resolve({
              snapshot: snapshot,
              url: res
            }))
        }, err => {
          console.error(err);
          reject(err);
        })
    })
  }

  createUser(uid: any, user: any) {
    return new Promise<any>((resolve, reject) => {
      console.log("createUser", user);
      this.afs.collection('users').doc(uid).set(user)
        .then(
          res => resolve(res),
          err => reject(err)
        )
    })
  }


  updateUser(user: any) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.authState.subscribe(currentUser => {
        if (currentUser) {
          user.id = currentUser.uid;

          console.log("updateUser", user);
          this.afs.collection('users').doc(user.id)
            .set(user)
            .then(
              res => resolve(res),
              err => reject(err)
            )
        }
      });
    });
  }


  getUserLogged() {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.authState.subscribe(currentUser => {
        if (currentUser) {
          resolve(currentUser);
        }
      })
    })
  }

  async getUserByCommuneLocality(locality: any) {

    return new Promise<any>((resolve, reject) => {
      let snapshotChangesRef = this.afs.collection('users', ref => ref.where('commune_locality', '==', locality));
      snapshotChangesRef.snapshotChanges()
        .pipe(take(1))
        .subscribe(snapshots => {
          let result = snapshots.map(a => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
          this.communes = result;
          resolve(result);
        }, err => {
          reject(err)
        })
    })
  }

  async getCurrentUser() {

    return await this.afAuth.currentUser;
  }
  async getUser(id: any = null) {
    return new Promise<any>(async (resolve, reject) => {
      if (id == null) {
        await this.afAuth.authState.subscribe(async currentUser => {
          console.log("currentUser", currentUser);
          if (currentUser) {
            id = currentUser.uid;
          }

          console.log("getUser", id);
          await this.afs.doc<any>('users/' + id).valueChanges()
            .pipe(take(1))
            .subscribe(snapshots => {
              console.log("snapshots", snapshots);
              resolve(snapshots);
            }, err => {
              reject(err)
            })
        });
      }
      else {
        console.log("getUser", id);
        await this.afs.doc<any>('users/' + id).valueChanges()
          .pipe(take(1))
          .subscribe(snapshots => {
            console.log("snapshots", snapshots);
            resolve(snapshots);
          }, err => {
            reject(err)
          })
      }
    });
  }

  unsubscribeOnLogOut() {
    //remember to unsubscribe from the snapshotChanges
    console.log("this.snapshotChangesSubscription", this.snapshotChangesSubscription);
    if (this.snapshotChangesSubscription != null)
      this.snapshotChangesSubscription.unsubscribe();
  }

  encodeImageUri(imageUri: string, callback: any) {
    var c = document.createElement('canvas');
    var ctx: any = c.getContext("2d");
    var img = new Image();
    img.onload = function () {
      var aux: any = this;
      c.width = aux.width;
      c.height = aux.height;
      ctx.drawImage(img, 0, 0);
      var dataURL = c.toDataURL("image/jpeg");
      callback(dataURL);
    };
    img.src = imageUri;
  };

  uploadImage(imageURI: string, randomId: string) {
    return new Promise<any>((resolve, reject) => {
      const storage = getStorage();
      const storageRef = ref(storage, 'image/' + randomId);
      this.encodeImageUri(imageURI, (image64: string) => {
        uploadString(storageRef, image64, 'data_url')
          .then(snapshot => {
            getDownloadURL(snapshot.ref)
              .then(res => resolve(res))
          }, err => {
            reject(err);
          })
      })
    })
  }

}
