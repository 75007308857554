import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonSlides, AlertController } from '@ionic/angular';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-tab1-pictures',
  templateUrl: './tab1-pictures.html',
  styleUrls: ['./tab1-pictures.page.scss'],
})
export class Tab1PicturesModal implements OnInit {
  alerte;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    console.log("alerte", this.alerte);
  }

  close() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}